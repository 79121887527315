import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Emitter, Emittable } from '@ngxs-labs/emitter';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ApplicationResponse } from 'src/app/dtos/ApplicationResponse';
import { ApiConnectorService } from 'src/app/services/api-connector.service';
import { AppState, AppStateModel, Screen } from 'src/app/states/AppState';
import { CurrentApplicationState } from 'src/app/states/CurrentApplicationState';
import { CurrentErrorState, ErrorModel } from 'src/app/states/CurrentErrorState';

@Component({
  selector: 'app-guarantee-upload',
  templateUrl: './guarantee-upload.component.html',
  styleUrl: './guarantee-upload.component.scss',
})
export class GuaranteeUploadComponent implements OnInit {
  constructor(
    private api: ApiConnectorService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  backendError: boolean = false;
  uploadForm: FormGroup = {} as FormGroup;
  @Emitter(CurrentErrorState.updateCurrentError)
  updateCurrentError$!: Emittable<ErrorModel>;
  @Select(CurrentErrorState.getCurrentError)
  getCurrentError$!: Observable<ErrorModel>;
  currentError: ErrorModel = {};
  @Select(CurrentApplicationState.getCurrentApplication)
  getCurrentApplication$!: Observable<ApplicationResponse>;
  currentApplication: ApplicationResponse = {};
  @Emitter(CurrentApplicationState.updateCurrentApplication)
  updateCurrentApplication$!: Emittable<ApplicationResponse>;
  @Select(AppState.getAppState)
  getAppState$!: Observable<AppStateModel>;
  appState: AppStateModel = {};
  @Emitter(AppState.updateAppState)
  updateAppState$!: Emittable<AppStateModel>;
  fileChanged: boolean = false;
  invalidType: boolean = false;
  file: File | null = null;
  allowedTypes: string[] = [
    'application/pdf',
    'image/png',
    'image/jpg',
    'image/jpeg',
  ];

  ngOnInit(): void {
    this.getCurrentError$.subscribe((value) => (this.currentError = value));
    this.getAppState$.subscribe((value) => (this.appState = value));
    this.appState.currentScreen = Screen.GUARANTEE_UPLOAD;
    this.updateAppState$.emit(this.appState);
    this.getCurrentApplication$.subscribe(
      (value) => (this.currentApplication = value)
    );

    this.uploadForm = this.formBuilder.group({
      guarantee: [null, [Validators.required]],
    });
  }

  onSubmit(): void {
    this.api
      .uploadGuarantee(
        this.currentApplication.applicationId!,
        this.file!
      )
      .subscribe({
        next: (response: ApplicationResponse) => {
          this.updateCurrentApplication$.emit(response);
          this.router.navigateByUrl('result');
        },
        error: (error) => {
          this.backendError = true;
          console.log(error);
          const message = error.headers.get('x-bawagpsk-frontend-error');
          console.log(message);

          if (message === 'MAIL_SERVICE_NOT_AVAILABLE') {
            this.currentError.mailServiceFailed = true;
            this.updateCurrentError$.emit(this.currentError);
            this.router.navigateByUrl('result');
          }
        },
      });
  }

  onChange(event: any) {
    this.file = null;
    this.invalidType = false;
    this.fileChanged = true;

    if (event.target.files[0] == null) {
      return;
    }

    if (!this.allowedTypes.includes(event.target.files[0].type)) {
      this.invalidType = true;
    } else {
      this.file = event.target.files[0];
    }
  }
}
