import { Pipe, PipeTransform } from '@angular/core';
import { IdTypesForAT } from '../dtos/IdType';

@Pipe({
  name: 'idType'
})
export class IdTypePipe implements PipeTransform {

  transform(value: string | undefined): string | null {

    if (value === undefined || value === null) {
      return null;
    }

    for (let i = 0; i < IdTypesForAT.length; i++) {
      if (IdTypesForAT[i].value === value) {
        return IdTypesForAT[i].label;
      }
    }
    return null;
  }

}
