import { Injectable } from '@angular/core';
import { Receiver, EmitterAction } from '@ngxs-labs/emitter';
import { State, Selector, StateContext } from '@ngxs/store';
import { BankInfoRequest } from '../dtos/BankInfoRequest';

export const EmptyBankInfoRequest = {
  bankName: undefined,
  blz: undefined,
  iban: undefined,
  accountSince: undefined,
  accountOk: undefined,
};

@State<BankInfoRequest>({
  name: 'bankInfo',
  defaults: EmptyBankInfoRequest,
})
@Injectable()
export class BankInfoState {
  @Selector()
  public static getBankInfo(state: BankInfoRequest): BankInfoRequest {
    return state;
  }
  @Receiver()
  public static updateBankInfo(
    { getState, patchState }: StateContext<BankInfoRequest>,
    { payload }: EmitterAction<BankInfoRequest>
  ) {
    const state = getState();
    patchState({
      ...state,
      ...payload,
    });
  }
}
