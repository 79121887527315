import { Injectable } from '@angular/core';
import { Selector, State, StateContext } from '@ngxs/store';
import { EmitterAction, Receiver } from '@ngxs-labs/emitter';
import { ApplicationResponse } from '../dtos/ApplicationResponse';

export const EmptyApplicationResponse = {
  applicationId: undefined,
  state: undefined,
};

@State<ApplicationResponse>({
  name: 'currentApplication',
  defaults: EmptyApplicationResponse,
})
@Injectable()
export class CurrentApplicationState {
  @Selector()
  public static getCurrentApplication(
    state: ApplicationResponse
  ): ApplicationResponse {
    return state;
  }
  @Receiver()
  public static updateCurrentApplication(
    { getState, patchState }: StateContext<ApplicationResponse>,
    { payload }: EmitterAction<ApplicationResponse>
  ) {
    const state = getState();
    patchState({
      ...state,
      ...payload,
    });
  }
}
