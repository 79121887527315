export enum PostposedAcademicTitle {
  BA = 'BA',
  BA_FH = 'BA_FH',
  BAKK = 'BAKK',
  BSC = 'BSC',
  MSC = 'MSC',
  PHD = 'PHD',
  MA = 'MA',
  MBA = 'MBA',
}

export const PostposedAcademicTitles = [
  { value: 'BA', label: 'BA' },
  { value: 'BA_FH', label: 'BA(FH)' },
  { value: 'BAKK', label: 'Bakk.' },
  { value: 'BSC', label: 'BSc' },
  { value: 'MSC', label: 'MSc' },
  { value: 'PHD', label: 'PhD' },
  { value: 'MBA', label: 'MBA' },
  { value: 'MA', label: 'MA' },
];
