<div class="mt-5 mw600">
  <div *ngIf="backendError" class="alert alert-danger" role="alert">
    Es ist ein Fehler aufgetreten
  </div>
  <form [formGroup]="legitimationInfoForm" (ngSubmit)="onSubmit()">
    <div class="mb-3">
      <label for="idTypeControl" class="form-label">Ausweisart: *</label>
      <ng-select
        id="idTypeControl"
        placeholder="Bitte die Ausweisart auswählen"
        [items]="idTypes"
        bindLabel="label"
        bindValue="value"
        formControlName="idType"
      >
      </ng-select>
      <div
        *ngIf="
          legitimationInfoForm.controls['idType'].errors &&
          legitimationInfoForm.controls['idType'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte die Ausweisart auswählen
      </div>
    </div>
    <div class="mb-3">
      <div class="row form-label">
        <div class="col">
          <label for="idNumberControl">Ausweisnummer: *</label>
        </div>
        <div class="col-auto text-end">
          <svg
            (click)="showIdNumberInfo = !showIdNumberInfo"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clip-path="url(#clip0_15_702)">
              <path
                d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"
                fill="#000066"
              />
            </g>
            <defs>
              <clipPath id="clip0_15_702">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>

      <div *ngIf="showIdNumberInfo" class="alert alert-info" role="alert">
        Die Ausweisnummer muss zwischen 8 und 15 Zeichen lang sein.
      </div>
      <input
        type="text"
        class="form-control"
        id="idNumberControl"
        placeholder="Bitte die Ausweisnummer eingeben"
        formControlName="idNumber"
      />
      <div
        *ngIf="
          legitimationInfoForm.controls['idNumber'].errors &&
          legitimationInfoForm.controls['idNumber'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte eine gültige Ausweisnummer eingeben
      </div>
    </div>
    <div class="mb-3">
      <label for="issuingAuthorityControl" class="form-label"
        >Ausstellende Behörde: *</label
      >
      <input
        type="text"
        class="form-control"
        id="issuingAuthorityControl"
        placeholder="Bitte die ausstellende Behörde eingeben"
        formControlName="issuingAuthority"
      />
      <div
        *ngIf="
          legitimationInfoForm.controls['issuingAuthority'].errors &&
          legitimationInfoForm.controls['issuingAuthority'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte eine gültige Behörde eingeben
      </div>
    </div>
    <div class="mb-3">
      <label for="issuingCountryControl" class="form-label"
        >Ausstellungsland: *</label
      >
      <ng-select
        id="issuingCountryControl"
        placeholder="Bitte das Ausstellungsland auswählen"
        [items]="countries"
        bindLabel="country"
        bindValue="countryCode"
        formControlName="issuingCountry"
      >
      </ng-select>
    </div>
    <div class="mb-3">
      <label for="issuingDateControl" class="form-label"
        >Ausstellungsdatum: *</label
      >
      <input
        type="date"
        class="form-control"
        id="issuingDateControl"
        formControlName="issuingDate"
        max="{{ today | date : 'yyyy-MM-dd' }}"
        min="{{ earliestDate | date : 'yyyy-MM-dd' }}"
      />
      <div
        *ngIf="
          legitimationInfoForm.controls['issuingDate'].errors &&
          legitimationInfoForm.controls['issuingDate'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte ein gültiges Datum eingeben
      </div>
    </div>
    <div *ngIf="showExpirationDate" class="mb-3">
      <label for="expirationDateControl" class="form-label"
        >Ablaufdatum: *</label
      >
      <input
        type="date"
        class="form-control"
        id="expirationDateControl"
        formControlName="expirationDate"
        min="{{ today | date : 'yyyy-MM-dd' }}"
        max="{{ maxExpirations | date : 'yyyy-MM-dd' }}"
      />
      <div
        *ngIf="
          legitimationInfoForm.controls['expirationDate'].errors &&
          legitimationInfoForm.controls['expirationDate'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte ein gültiges Datum eingeben
      </div>
    </div>

    <div class="container-fluid">
      <div class="row p-0">
        <div class="col text-start p-0">
          <button type="button" (click)="back()" class="btn btn-primary">
            Zurück
          </button>
        </div>
        <div class="col text-end p-0">
          <button
            type="submit"
            [disabled]="legitimationInfoForm.invalid"
            class="btn btn-primary"
          >
            {{ submitText }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
