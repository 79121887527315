import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export function divisableBy(value: number): ValidatorFn {
  return (control: AbstractControl) => {
    if (control.value === null) {
      return null;
    }

    let rest = control.value % value;

    if (rest != 0) {
      return { notDevisable: true };
    }
    return null;
  };
}

export function isBefore(date: Date): ValidatorFn {
  return (control: AbstractControl) => {
    if (control.value === null) {
      return null;
    }

    let controlDate = new Date(control.value);

    if (controlDate > date) {
      return { toLate: true };
    }
    return null;
  };
}

export function isAfter(date: Date): ValidatorFn {
  return (control: AbstractControl) => {
    if (control.value === null) {
      return null;
    }

    let controlDate = new Date(control.value);

    if (controlDate < date) {
      return { toEarly: true };
    }
    return null;
  };
}

export function startsNotWithZero(): ValidatorFn {
  return (control: AbstractControl) => {
    let value: string = control.value;

    if (value !== null && value.startsWith('0')) {
      return { startsWithZero: true };
    }
    return null;
  };
}

export function validIban(): ValidatorFn {
  return (control: AbstractControl) => {
    let map = new Map();
    map.set('A', 10);
    map.set('B', 11);
    map.set('C', 12);
    map.set('D', 13);
    map.set('E', 14);
    map.set('F', 15);
    map.set('G', 16);
    map.set('H', 17);
    map.set('I', 18);
    map.set('J', 19);
    map.set('K', 20);
    map.set('L', 21);
    map.set('M', 22);
    map.set('N', 23);
    map.set('O', 24);
    map.set('P', 25);
    map.set('Q', 26);
    map.set('R', 27);
    map.set('S', 28);
    map.set('T', 29);
    map.set('U', 30);
    map.set('V', 31);
    map.set('W', 32);
    map.set('X', 33);
    map.set('Y', 34);
    map.set('Z', 35);

    try {
      let iban: string = control.value;
      let bban: string = iban.substring(4);
      let countryCode: string = iban.substring(0, 2);
      let expectedResult: bigint = BigInt(iban.substring(2, 4));

      let firstChar: number = map.get(countryCode.charAt(0));
      let secondChar: number = map.get(countryCode.charAt(1));
      let checksum: bigint = BigInt(bban + firstChar + secondChar + '00');

      let result: bigint = BigInt(98) - (checksum % BigInt(97));

      if (expectedResult === result) {
        return null;
      }

      return { invalidIban: true };
    } catch (e) {
      return { invalidIban: true };
    }
  };
}

export function atLeastOneIncomeType(): ValidatorFn {
  return (form: AbstractControl) => {
    const workingIncome: boolean = form.get('workingIncome')!.value;
    const selfEmployedIncome: boolean = form.get('selfEmployedIncome')!.value;
    const stateIncome: boolean = form.get('stateIncome')!.value;
    const ongoingIncome: boolean = form.get('ongoingIncome')!.value;
    const apprenticeIncome: boolean = form.get('apprenticeIncome')!.value;
    const inheritIncome: boolean = form.get('inheritIncome')!.value;
    const saleIncome: boolean = form.get('saleIncome')!.value;
    const insuranceIncome: boolean = form.get('insuranceIncome')!.value;
    const otherIncome: boolean = form.get('otherIncome')!.value;
    const spendingIncome: boolean = form.get('spendingIncome')!.value;

    let result: boolean =
      workingIncome ||
      selfEmployedIncome ||
      stateIncome ||
      ongoingIncome ||
      apprenticeIncome ||
      inheritIncome ||
      saleIncome ||
      insuranceIncome ||
      spendingIncome ||
      otherIncome;

    if (!result) {
      return { missingIncome: true };
    }

    return null;
  };
}

export function atLeastOneIncomeTypeForStudent(): ValidatorFn {
  return (form: AbstractControl) => {
    const workingIncome: boolean = form.get('workingIncome')!.value;
    const selfEmployedIncome: boolean = form.get('selfEmployedIncome')!.value;
    const stateIncome: boolean = form.get('stateIncome')!.value;
    const apprenticeIncome: boolean = form.get('apprenticeIncome')!.value;

    let result: boolean =
      workingIncome || selfEmployedIncome || stateIncome || apprenticeIncome;

    if (!result) {
      return { missingIncome: true };
    }

    return null;
  };
}

export function atLeasOnePurpose(): ValidatorFn {
  return (form: AbstractControl) => {
    const cardPayment: boolean = form.get('cardPayment')!.value;
    const internetPayment: boolean = form.get('internetPayment')!.value;
    const drawCash: boolean = form.get('drawCash')!.value;

    let result: boolean = cardPayment || internetPayment || drawCash;

    if (!result) {
      return { missingPurpose: true };
    }

    return null;
  };
}
