export enum Occupation {
  EMPLOYEE = 'EMPLOYEE',
  PENSIONER = 'PENSIONER',
  OFFICIAL_SERVICE = 'OFFICIAL_SERVICE',
  WORKER = 'WORKER',
  STUDENT = 'STUDENT',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
  JOBLESS = 'JOBLESS',
  ON_LEAVE = 'ON_LEAVE',
}

export const Occupations = [
  { value: 'EMPLOYEE', label: 'Angestellte/r' },
  { value: 'PENSIONER', label: 'Pensionist/in' },
  { value: 'OFFICIAL_SERVICE', label: 'Beamte/r' },
  { value: 'WORKER', label: 'Arbeiter/in' },
  { value: 'STUDENT', label: 'in Ausbildung/Student/in' },
  { value: 'SELF_EMPLOYED', label: 'Selbständige/r' },
  { value: 'JOBLESS', label: 'ohne Beschäftigung' },
  { value: 'ON_LEAVE', label: 'in Karenz' },
];
