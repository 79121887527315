import { Pipe, PipeTransform } from '@angular/core';
import { PaybackPlans } from '../dtos/PaybackPlan';

@Pipe({
  name: 'paybackPlan'
})
export class PaybackPlanPipe implements PipeTransform {

  transform(value: string | undefined): string | null {

    if (value === undefined || value === null) {
      return null;
    }

    for (let i = 0; i < PaybackPlans.length; i++) {
      if (PaybackPlans[i].value === value) {
        return PaybackPlans[i].label;
      }
    }
    return null;
  }

}
