import { Pipe, PipeTransform } from '@angular/core';
import { Salutations } from '../dtos/Salutation';

@Pipe({
  name: 'salutation',
})
export class SalutationPipe implements PipeTransform {
  transform(value: string | undefined): string | null {

    if (value === undefined || value === null) {
      return null;
    }

    for (let i = 0; i < Salutations.length; i++) {
      if (Salutations[i].value === value) {
        return Salutations[i].label;
      }
    }
    return null;
  }
}
