<div class="mt-5 mw600">
  <div *ngIf="backendError" class="alert alert-danger" role="alert">
    Es ist ein Fehler aufgetreten
  </div>
  <form [formGroup]="personalInfoForm" (ngSubmit)="onSubmit()">
    <div class="mb-3">
      <b>Alle mit * markierten Felder sind Pflichtfelder</b>
    </div>
    <div class="mb-3">
      <label for="salutationControl" class="form-label">Anrede: *</label>
      <ng-select
        id="salutationControl"
        placeholder="Bitte die Anrede auswählen"
        [items]="salutations"
        bindLabel="label"
        bindValue="value"
        formControlName="salutation"
      >
      </ng-select>
      <div
        *ngIf="
          personalInfoForm.controls['salutation'].errors &&
          personalInfoForm.controls['salutation'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Eine Anrede muss ausgewählt werden
      </div>
    </div>
    <div class="mb-3">
      <label for="academicTitleControl" class="form-label"
        >Akademischer Titel:</label
      >
      <ng-select
        id="academicTitleControl"
        placeholder="Bitte den akademischen Titel auswählen"
        [items]="academicTitles"
        bindLabel="label"
        bindValue="value"
        formControlName="academicTitle"
      >
      </ng-select>
    </div>
    <div class="mb-3">
      <label for="postposedAcademicTitleControl" class="form-label"
        >Akademischer Titel nachgestellt:</label
      >
      <ng-select
        id="postposedAcademicTitleControl"
        placeholder="Bitte den nachgestellten akademischen Titel auswählen"
        [items]="postposedAcademicTitles"
        bindLabel="label"
        bindValue="value"
        formControlName="postposedAcademicTitle"
      >
      </ng-select>
    </div>
    <div class="mb-3">
      <label for="occupationTitleControl" class="form-label"
        >Berufstitel:</label
      >
      <ng-select
        id="occupationTitleControl"
        placeholder="Bitte den Berufstitel auswählen"
        [items]="occupationTitles"
        bindLabel="label"
        bindValue="value"
        formControlName="occupationTitle"
      >
      </ng-select>
    </div>
    <div class="mb-3">
      <label for="firstNameControl" class="form-label">Vorname: *</label>
      <input
        type="text"
        class="form-control"
        id="firstNameControl"
        placeholder="Bitte den Vornamen eingeben"
        formControlName="firstName"
      />
      <div
        *ngIf="
          personalInfoForm.controls['firstName'].errors &&
          personalInfoForm.controls['firstName'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte einen gültigen Vornamen eingeben
      </div>
    </div>
    <div class="mb-3">
      <label for="lastNameControl" class="form-label">Nachname: *</label>
      <input
        type="text"
        class="form-control"
        id="lastNameControl"
        placeholder="Bitte den Nachnamen eingeben"
        formControlName="lastName"
      />
      <div
        *ngIf="
          personalInfoForm.controls['lastName'].errors &&
          personalInfoForm.controls['lastName'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte einen gültigen Nachnamen eingeben
      </div>
    </div>
    <div class="mb-3">
      <label for="birthdayControl" class="form-label">Geburtsdatum: *</label>
      <input
        type="date"
        class="form-control"
        id="birthdayControl"
        formControlName="birthday"
        max="{{ latestPossibleBirthday | date : 'yyyy-MM-dd' }}"
        min="{{ earliestDate | date : 'yyyy-MM-dd' }}"
      />
      <div
        *ngIf="
          personalInfoForm.controls['birthday'].errors &&
          personalInfoForm.controls['birthday'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte ein gültiges Datum eingeben
      </div>
    </div>
    <div class="mb-3">
      <label for="placeOfBirthControl" class="form-label">Geburtsort: *</label>
      <input
        type="text"
        class="form-control"
        id="placeOfBirthControl"
        placeholder="Bitte den Geburtsort eingeben"
        formControlName="placeOfBirth"
      />
      <div
        *ngIf="
          personalInfoForm.controls['placeOfBirth'].errors &&
          personalInfoForm.controls['placeOfBirth'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte einen gültigen Geburtsort eingeben
      </div>
    </div>
    <div class="mb-3">
      <label for="countryOfBirthControl" class="form-label"
        >Geburtsland: *</label
      >
      <ng-select
        id="countryOfBirthControl"
        placeholder="Bitte das Geburtsland auswählen"
        [items]="countries"
        bindLabel="country"
        bindValue="countryCode"
        formControlName="countryOfBirth"
      >
      </ng-select>
      <div
        *ngIf="
          personalInfoForm.controls['countryOfBirth'].errors &&
          personalInfoForm.controls['countryOfBirth'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte ein Land auswählen
      </div>
    </div>
    <div class="mb-3">
      <label for="citizenshipControl" class="form-label"
        >Staatsangehörigkeit: *</label
      >
      <ng-select
        id="citizenshipControl"
        placeholder="Bitte die Staatsangehörigkeit auswählen"
        [items]="citizenshipCountries"
        bindLabel="country"
        bindValue="countryCode"
        formControlName="citizenship"
      >
      </ng-select>
      <div
        *ngIf="
          personalInfoForm.controls['citizenship'].errors &&
          personalInfoForm.controls['citizenship'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte ein Land auswählen
      </div>
    </div>
    <div class="mb-3" *ngIf="inAtSinceRequired">
      <label for="inAtSinceControl" class="form-label"
        >In Österreich seit: *</label
      >
      <input
        type="date"
        class="form-control"
        id="inAtSinceControl"
        formControlName="inAtSince"
        max="{{ today | date : 'yyyy-MM-dd' }}"
        min="{{ earliestDate | date : 'yyyy-MM-dd' }}"
      />
      <div
        *ngIf="
          personalInfoForm.controls['inAtSince'].errors &&
          personalInfoForm.controls['inAtSince'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte ein gültiges Datum auswählen
      </div>
    </div>
    <div class="mb-3">
      <label for="maritalStateControl" class="form-label"
        >Familienstand: *</label
      >
      <ng-select
        id="maritalStateControl"
        placeholder="Bitte den Familienstand auswählen"
        [items]="maritalStates"
        bindLabel="label"
        bindValue="value"
        formControlName="maritalState"
      >
      </ng-select>
      <div
        *ngIf="
          personalInfoForm.controls['maritalState'].errors &&
          personalInfoForm.controls['maritalState'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte den Familienstand auswählen
      </div>
    </div>
    <div class="mb-3">
      <label for="educationControl" class="form-label"
        >Höchster Bildungsabschluss: *</label
      >
      <ng-select
        id="educationControl"
        placeholder="Bitte die Ausbildung auswählen"
        [items]="educations"
        bindLabel="label"
        bindValue="value"
        formControlName="education"
      >
      </ng-select>
      <div
        *ngIf="
          personalInfoForm.controls['education'].errors &&
          personalInfoForm.controls['education'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte die Ausbildung auswählen
      </div>
    </div>
    <div class="mb-3">
      <div class="row form-label">
        <div class="col">
          <label for="embossingLine1Control">Prägezeile 1: *</label>
        </div>
        <div class="col-auto text">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            (click)="showEmbossingInfo1 = !showEmbossingInfo1"
          >
            <g clip-path="url(#clip0_15_702)">
              <path
                d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"
                fill="#000066"
              />
            </g>
            <defs>
              <clipPath id="clip0_15_702">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>

      <div *ngIf="showEmbossingInfo1" class="alert alert-info" role="alert">
        In der Prägezeile 1 wird der Vorname und Nachname übernommen. Die
        Prägezeile darf maximal 26 Zeichen lang sein. Es sind nur Großbuchstaben
        ohne Umlaute, sowie Leerzeichen, Schrägstrich, Bindestrich, Punkt und
        das & Zeichen erlaubt. Es können zum Beispiel voran- oder nachgestellte
        akademische Titel eingefügt werden. (Beispiel: DR. ANNA MUSTER BA)
      </div>
      <input
        type="text"
        class="form-control"
        id="embossingLine1Control"
        placeholder="Bitte die Prägezeile 1 eingeben"
        formControlName="embossingLine1"
        (input)="onEmbossing1Change($event)"
      />
      <div
        *ngIf="
          personalInfoForm.controls['embossingLine1'].errors &&
          personalInfoForm.controls['embossingLine1'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte eine gültige Prägezeile eingeben
      </div>
    </div>
    <div class="mb-3">
      <div class="row form-label">
        <div class="col">
          <label for="embossingLine2Control">Prägezeile 2:</label>
        </div>
        <div class="col-auto text-end">
          <svg
            (click)="showEmbossingInfo2 = !showEmbossingInfo2"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clip-path="url(#clip0_15_702)">
              <path
                d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"
                fill="#000066"
              />
            </g>
            <defs>
              <clipPath id="clip0_15_702">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>

      <div *ngIf="showEmbossingInfo2" class="alert alert-info" role="alert">
        In der Prägezeile 2 können weitere Informationen auf die Karte geprägt
        werden. Die Prägezeile darf maximal 26 Zeichen lang sein. Es sind nur
        Großbuchstaben ohne Umlaute, sowie Leerzeichen, Schrägstrich,
        Bindestrich, Punkt und das & Zeichen erlaubt. Es können zum Beispiel
        Berufstitel eingefügt werden (Beispiel: HOFRAETIN).
      </div>
      <input
        type="text"
        class="form-control"
        id="embossingLine2Control"
        placeholder="Bitte die Prägezeile 2 eingeben"
        formControlName="embossingLine2"
        (input)="onEmbossing2Change($event)"
      />
      <div
        *ngIf="
          personalInfoForm.controls['embossingLine2'].errors &&
          personalInfoForm.controls['embossingLine2'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte eine gültige Prägezeile eingeben
      </div>
    </div>
    <div class="container-fluid">
      <div class="row p-0">
        <div class="col text-start p-0">
          <button type="button" (click)="back()" class="btn btn-primary">
            Zurück
          </button>
        </div>
        <div class="col text-end p-0">
          <button
            type="submit"
            [disabled]="personalInfoForm.invalid"
            class="btn btn-primary"
          >
            {{ submitText }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
