import { Pipe, PipeTransform } from '@angular/core';
import { Peps } from '../dtos/Pep';

@Pipe({
  name: 'pep'
})
export class PepPipe implements PipeTransform {

  transform(value: string | undefined): string | null {

    if (value === undefined || value === null) {
      return null;
    }

    for (let i = 0; i < Peps.length; i++) {
      if (Peps[i].value === value) {
        return Peps[i].label;
      }
    }
    return null;
  }

}
