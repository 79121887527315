//[ UNIVERSITY, FH, MATURA, APPRENTICESHIP, POLY, COMPULSORY, COMMERCIAL ]

export enum Education {
  UNIVERSITY = 'UNIVERSITY',
  FH = 'FH',
  MATURA = 'MATURA',
  APPRENTICESHIP = 'APPRENTICESHIP',
  POLY = 'POLY',
  COMPULSORY = 'COMPULSORY',
  COMMERCIAL = 'COMMERCIAL',
}

export const Educations = [
  { value: 'UNIVERSITY', label: 'Universität' },
  { value: 'FH', label: 'Fachhochschule' },
  { value: 'MATURA', label: 'Matura' },
  { value: 'APPRENTICESHIP', label: 'Lehre' },
  { value: 'POLY', label: 'Polytechnikum' },
  { value: 'COMPULSORY', label: 'Pflichtschule' },
  { value: 'COMMERCIAL', label: 'Handelsschule' },
];
