export enum IdType {
  PASSPORT = 'PASSPORT',
  CONVENTION_PASSPORT = 'CONVENTION_PASSPORT',
  DRIVER_LICENSE = 'DRIVER_LICENSE',
  DRIVER_LICENSE_15 = 'DRIVER_LICENSE_15',
  ID_CARD = 'ID_CARD',
}

export const IdTypesForAT = [
  { value: 'PASSPORT', label: 'Reisepass' },
  {
    value: 'DRIVER_LICENSE',
    label: 'Österreichischer Führerschein ohne Ablaufdatum',
  },
  {
    value: 'DRIVER_LICENSE_15',
    label: 'Österreichischer Führerschein 15 Jahre',
  },
  { value: 'ID_CARD', label: 'Personalausweis' },
];

export const IdTypesForEU = [
  { value: 'PASSPORT', label: 'Reisepass' },
  { value: 'ID_CARD', label: 'Personalausweis' },
];

export const IdTypesForNonEU = [
  { value: 'PASSPORT', label: 'Reisepass' },
  { value: 'CONVENTION_PASSPORT', label: 'Konventionsreisepass' },
];
