<div class="mt-3">
  <div *ngIf="backendError" class="alert alert-danger" role="alert">
    Es ist ein Fehler aufgetreten
  </div>

  <div class="alert alert-info mb-5" role="alert">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clip-path="url(#clip0_74_47)">
        <path
          d="M10.0003 18.3333C5.39783 18.3333 1.66699 14.6025 1.66699 9.99999C1.66699 5.39749 5.39783 1.66666 10.0003 1.66666C14.6028 1.66666 18.3337 5.39749 18.3337 9.99999C18.3337 14.6025 14.6028 18.3333 10.0003 18.3333ZM10.0003 16.6667C11.7684 16.6667 13.4641 15.9643 14.7144 14.714C15.9646 13.4638 16.667 11.7681 16.667 9.99999C16.667 8.23188 15.9646 6.53619 14.7144 5.28594C13.4641 4.0357 11.7684 3.33332 10.0003 3.33332C8.23222 3.33332 6.53652 4.0357 5.28628 5.28594C4.03604 6.53619 3.33366 8.23188 3.33366 9.99999C3.33366 11.7681 4.03604 13.4638 5.28628 14.714C6.53652 15.9643 8.23222 16.6667 10.0003 16.6667ZM9.16699 5.83332H10.8337V7.49999H9.16699V5.83332ZM9.16699 9.16666H10.8337V14.1667H9.16699V9.16666Z"
          fill="#75B8D9"
        />
      </g>
      <defs>
        <clipPath id="clip0_74_47">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
    <small>
      Bitte beachten Sie, dass nur die Beantragung von Hauptkarten möglich ist.
      Partnerkarten bitte weiterhin über PDF-Kartenauftrag bestellen.</small
    >
  </div>

  <h2 class="mb-4">Benötigte Dokumente für einen PayLife-Kartenantrag</h2>
  <div class="container">
    <div class="row mb-1">
      <div class="col-auto px-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_106_379)">
            <path
              d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM11.003 16L6.76 11.757L8.174 10.343L11.003 13.172L16.659 7.515L18.074 8.929L11.003 16Z"
              fill="#000066"
            />
          </g>
          <defs>
            <clipPath id="clip0_106_379">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="col">Kopie des Ausweises des Antragsstellers</div>
    </div>
    <div class="row mb-1">
      <div class="col-auto px-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_106_379)">
            <path
              d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM11.003 16L6.76 11.757L8.174 10.343L11.003 13.172L16.659 7.515L18.074 8.929L11.003 16Z"
              fill="#000066"
            />
          </g>
          <defs>
            <clipPath id="clip0_106_379">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="col">
        Handelt es sich bei dem Antragsteller um einen Drittstaatangehörigen
        (Nicht EWR-/EFTA-Staatsbürger) ist ein gültiger Aufenthaltstitel hoch zu
        laden
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-auto px-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_106_379)">
            <path
              d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM11.003 16L6.76 11.757L8.174 10.343L11.003 13.172L16.659 7.515L18.074 8.929L11.003 16Z"
              fill="#000066"
            />
          </g>
          <defs>
            <clipPath id="clip0_106_379">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="col">
        Erfüllt der Antragsteller nicht alle Vorgaben der derzeit gültigen
        Vergaberichtlinie, wird außerdem für die Ausstellung einer PayLife
        Kreditkarte eine Bank-Garantie benötigt, welche auch im Zuge der
        Antragstrecke hoch zu laden
      </div>
    </div>
  </div>

  <div class="container mt-5">
    <div class="row">
      <div class="col card mybox mx-2">
        <div class="card-body p-4">
          <h5 class="card-title mb-3">So funktioniert der Kartenantrag</h5>
          <a
            class="link-offset-2 link-underline link-underline-opacity-0"
            href="../../../../assets/files/Antragstrecke-PayLife_03-2024.pdf"
            target="_blank"
          >
            <b>Anleitung (PDF) &#10132;</b>
          </a>
        </div>
      </div>
      <div class="col card mybox mx-2">
        <div class="card-body p-4">
          <h5 class="card-title mb-3">
            Beachten Sie die Einhaltung der Vergaberichtlinine
          </h5>
          <a
            class="link-offset-2 link-underline link-underline-opacity-0"
            href="../../../../assets/files/Vergaberichtlinien-Privat-u-Business-02_2024.pdf"
            target="_blank"
            ><b>Vergaberichtlinie &#10132;</b>
          </a>
        </div>
      </div>
      <div class="col card mybox mx-2">
        <div class="card-body p-4">
          <h5 class="card-title mb-3">Soviel kosten PayLife Kreditkarten</h5>
          <a
            class="link-offset-2 link-underline link-underline-opacity-0"
            href="https://www.paylife.at/linkableblob/-/472244/6d2a47f5fe28243f7cd6497de72f0f7f/preisblatt-paylife-kreditkarten-aut-dt-pdf-data.pdf"
            target="_blank"
            ><b>Preisblatt &#10132;</b>
          </a>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col card mybox mx-2">
        <div class="card-body p-4">
          <h5 class="card-title mb-3">Welche Karte passt zu Ihren Kunden?</h5>
          <a
            class="link-offset-2 link-underline link-underline-opacity-0"
            href="https://www.paylife.at/paylife/private/credit-cards"
            target="_blank"
          >
            <b>Karten vergleichen &#10132;</b>
          </a>
        </div>
      </div>
      <div class="col card mybox mx-2">
        <div class="card-body p-4">
          <h5 class="card-title mb-3">Überblick Versicherungsleistungen</h5>
          <a
            class="link-offset-2 link-underline link-underline-opacity-0"
            href="../../../../assets/files/Vergleich-Versicherungsleistungen-PayLife.pdf"
            target="_blank"
            ><b>Versicherungsleistungen &#10132;</b>
          </a>
        </div>
      </div>
      <div class="col card mybox mx-2">
        <div class="card-body p-4">
          <h5 class="card-title mb-3">
            In Einzelfällen notwendig: Die Garantieerklärung
          </h5>
          <a
            class="link-offset-2 link-underline link-underline-opacity-0"
            href="https://www.paylife.at/linkableblob/paylife/535284/eae12f7217d5d8c194c0d53da1d6f6fc/kk-
      garantieerklaerung-2022-data.pdf"
            target="_blank"
            ><b>Garantieerklärung &#10132;</b>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="mt-5">
    <b>PayLife Bonus 100 Aktion</b>
    <diV class="mt-3"
      >Vom 01.04 bis 31.05.2024 gilt unsere PayLife Bonus Aktion für PayLife
      GoldPlus oder Gold Karten. Kunden können sich im ersten Jahr bis zu 100
      Euro zurückholen, denn für jede Zahlung über 50,01 Euro wird 1 Euro
      gutgeschrieben. Details zu der Aktion:
      <a target="_blank" href="https://www.paylife.at/paylife/privat/bonus100"
        >https://www.paylife.at/paylife/privat/bonus100</a
      ></diV
    >
  </div> -->
</div>
