<div class="bg-light fixed-top shadow-sm">
  <div class="container px-5">
    <div class="row">
      <div class="col-auto">
        <a routerLink=""
          ><img class="img-fluid mw150" src="./../assets/images/logo.png"
        /></a>
      </div>
      <div class="col p-3 text-end">
        <button
          *ngIf="onHome"
          (click)="manageAccount()"
          class="btn btn-light"
        >
          Mein Account
        </button>
        <button
          *ngIf="onHome"
          (click)="onSubmit()"
          class="btn btn-primary mx-2"
        >
          Antrag starten
        </button>
        <button
          *ngIf="onHome"
          title="Logout"
          (click)="logout()"
          class="btn btn-light"
        >
          <svg
            (click)="logout()"
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
          >
            <path
              d="M5.20833 21.875C4.63542 21.875 4.14514 21.6712 3.7375 21.2635C3.32986 20.8559 3.12569 20.3653 3.125 19.7917V5.20833C3.125 4.63542 3.32917 4.14514 3.7375 3.7375C4.14583 3.32986 4.63611 3.12569 5.20833 3.125H12.5V5.20833H5.20833V19.7917H12.5V21.875H5.20833ZM16.6667 17.7083L15.2344 16.1979L17.8906 13.5417H9.375V11.4583H17.8906L15.2344 8.80208L16.6667 7.29167L21.875 12.5L16.6667 17.7083Z"
              fill="#4A4A4A"
            />
          </svg>
        </button>
        <button *ngIf="!onHome" (click)="back()" class="btn btn-light">
          Antrag abbrechen
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <g clip-path="url(#clip0_74_244)">
              <path
                d="M12.0002 11.086L16.9502 6.13599L18.3642 7.54999L13.4142 12.5L18.3642 17.45L16.9502 18.864L12.0002 13.914L7.05023 18.864L5.63623 17.45L10.5862 12.5L5.63623 7.54999L7.05023 6.13599L12.0002 11.086Z"
                fill="#4A4A4A"
              />
            </g>
            <defs>
              <clipPath id="clip0_74_244">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(0 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
    </div>
  </div>
</div>
