import { Injectable } from '@angular/core';
import { Receiver, EmitterAction } from '@ngxs-labs/emitter';
import { State, Selector, StateContext } from '@ngxs/store';
import { LegitimationInfoRequest } from '../dtos/LegitimationInfoRequest';


export const EmptyLegitimationInfoRequest = {
  idType: undefined,
  idNumber: undefined,
  issuingAuthority: undefined,
  expirationDate: undefined,
  issuingCountry: undefined,
  issuingDate: undefined,
}

@State<LegitimationInfoRequest>({
  name: 'legitimationInfo',
  defaults: EmptyLegitimationInfoRequest,
})
@Injectable()
export class LegitimationInfoState {
  @Selector()
  public static getLegitimationInfo(state: LegitimationInfoRequest): LegitimationInfoRequest {
    return state;
  }
  @Receiver()
  public static updateLegitimationInfo(
    { getState, patchState }: StateContext<LegitimationInfoRequest>,
    { payload }: EmitterAction<LegitimationInfoRequest>
  ) {
    const state = getState();
    patchState({
      ...state,
      ...payload,
    });
  }
}
