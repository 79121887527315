import { Injectable } from '@angular/core';
import { Receiver, EmitterAction } from '@ngxs-labs/emitter';
import { State, Selector, StateContext } from '@ngxs/store';

export enum Screen {
  HOME = 'home',
  PERSONAL_INFO = 'personal-info',
  CONTACT_INFO = 'contact-info',
  LEGITIMATION_INFO = 'legitimaion-info',
  BANK_INOF = 'bank-info',
  FINANCIAL_INFO = 'financial-info',
  PRODUCT_SELECTION = 'product-selection',
  BASIC_INFO = 'basic-info',
  SERVICE_INFO = 'service-info',
  FILE_UPLOAD = 'file-upload',
  SUMMARY = 'summary',
  SIGN = 'sign',
  RESULT = 'result',
  GUARANTEE_UPLOAD = 'guarantee-upload',
}

export interface AppStateModel {
  summaryReached?: boolean;
  serviceAccepted?: boolean;
  currentScreen?: Screen;
}

export const InitialAppStateModel = {
  summaryReached: false,
  serviceAccepted: false,
  currentScreen: undefined,
};

@State<AppStateModel>({
  name: 'appState',
  defaults: InitialAppStateModel,
})
@Injectable()
export class AppState {
  @Selector()
  public static getAppState(state: AppStateModel): AppStateModel {
    return state;
  }
  @Receiver()
  public static updateAppState(
    { getState, patchState }: StateContext<AppStateModel>,
    { payload }: EmitterAction<AppStateModel>
  ) {
    const state = getState();
    patchState({
      ...state,
      ...payload,
    });
  }
}
