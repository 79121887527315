import { Pipe, PipeTransform } from '@angular/core';
import { Industries } from '../dtos/Industry';

@Pipe({
  name: 'industry'
})
export class IndustryPipe implements PipeTransform {

  transform(value: string | undefined): string | null {

    if (value === undefined || value === null) {
      return null;
    }

    for (let i = 0; i < Industries.length; i++) {
      if (Industries[i].value === value) {
        return Industries[i].label;
      }
    }
    return null;
  }

}
