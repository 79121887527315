import { Pipe, PipeTransform } from '@angular/core';
import { Conditions } from '../dtos/Condition';

@Pipe({
  name: 'condition',
})
export class ConditionPipe implements PipeTransform {
  transform(value: string | undefined): string | null {
    if (value === undefined || value === null) {
      return null;
    }

    for (let i = 0; i < Conditions.length; i++) {
      if (Conditions[i].value === value) {
        return Conditions[i].label;
      }
    }
    return null;
  }
}
