<div class="mt-5 mw600">
  <div *ngIf="backendError" class="alert alert-danger" role="alert">
    Es ist ein Fehler aufgetreten
  </div>
  <form [formGroup]="uploadForm" (ngSubmit)="onSubmit()">
    <div class="mb-3"><b>Dokument hochladen: </b></div>
    <div class="mb-3">
      <label for="guaranteeControl" class="form-label"
        >Garantieerklärung *</label
      >
      <input
        type="file"
        class="form-control"
        id="guaranteeControl"
        placeholder="Bitte die Garantieerklärung auswählen"
        formControlName="guarantee"
        (change)="onChange($event)"
      />
      <div
        *ngIf="
          (uploadForm.controls['guarantee'].errors && fileChanged) ||
          invalidType
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte ein gültiges Dokument auswählen (pdf, png, jpg, jpeg)
      </div>
    </div>
    <div class="text-end">
      <button
        type="submit"
        [disabled]="uploadForm.invalid || invalidType"
        class="btn btn-primary"
      >
        Antrag abschließen
      </button>
    </div>
  </form>
</div>
