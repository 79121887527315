import { Injectable } from '@angular/core';
import { Selector, State, StateContext } from '@ngxs/store';
import { EmitterAction, Receiver } from '@ngxs-labs/emitter';

export interface ErrorModel {
  mailServiceFailed?: boolean;
  initFailed?: boolean;
}

export const EmptyErrorModel = {
  mailServiceFailed: false,
  initFailed: false,
};

@State<ErrorModel>({
  name: 'currentErrorModel',
  defaults: EmptyErrorModel,
})
@Injectable()
export class CurrentErrorState {
  @Selector()
  public static getCurrentError(state: ErrorModel): ErrorModel {
    return state;
  }
  @Receiver()
  public static updateCurrentError(
    { getState, patchState }: StateContext<ErrorModel>,
    { payload }: EmitterAction<ErrorModel>
  ) {
    const state = getState();
    patchState({
      ...state,
      ...payload,
    });
  }
}
