export enum PoliticalFunction {
  GOVERNMENT = 'GOVERNMENT',
  PARLIAMENT = 'PARLIAMENT',
  PARTY = 'PARTY',
  COURT = 'COURT',
  AUDITOR_OR_CENTRAL_BANK = 'AUDITOR_OR_CENTRAL_BANK',
  AMBASSADOR_OR_MILITARY_OFFICER = 'AMBASSADOR_OR_MILITARY_OFFICER',
  GOVERNMENTAL_COMPANY = 'GOVERNMENTAL_COMPANY',
  INTERNATIONAL_ORGANISATION = 'INTERNATIONAL_ORGANISATION',
}

export const PoliticalFunctions = [
  {
    value: 'GOVERNMENT',
    label:
      'Staatsoberhäupter, Regierungschefs, Minister und deren Stellvertreter und Staatssekretäre',
  },
  { value: 'PARLIAMENT', label: 'Parlamentsmitglieder' },
  {
    value: 'PARTY',
    label: 'Mitglieder der Führungsgremien politischer Parteien',
  },
  {
    value: 'COURT',
    label:
      'Mitglieder von obersten Gerichten, Verfassungsgerichten oder sonstigen hochrangigen Institutionen der Justiz',
  },
  {
    value: 'AUDITOR_OR_CENTRAL_BANK',
    label: 'Mitglieder von Rechnungshöfen, Leitungsorgane von Zentralbanken',
  },
  {
    value: 'AMBASSADOR_OR_MILITARY_OFFICER',
    label:
      'Botschafter, Geschäftsträger oder hochrangige Offiziere der Streitkräfte',
  },
  {
    value: 'GOVERNMENTAL_COMPANY',
    label:
      'Mitglieder der Verwaltungs-, Leitungs- oder Aufsichtsorgane staatlicher Unternehmen',
  },
  {
    value: 'INTERNATIONAL_ORGANISATION',
    label:
      'Direktoren/Stv. und Mitglieder der Leitungsorgane einer internationalen Organisation',
  },
];
