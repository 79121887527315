import { Pipe, PipeTransform } from '@angular/core';
import { OccupationTitles } from '../dtos/OccupationTitle';

@Pipe({
  name: 'occupationTitle',
})
export class OccupationTitlePipe implements PipeTransform {
  transform(value: string | undefined): string | null {



    if (value === undefined || value === null) {
      return null;
    }

    for (let i = 0; i < OccupationTitles.length; i++) {
      if (OccupationTitles[i].value === value) {
        return OccupationTitles[i].label;
      }
    }

    return null;
  }
}
