import { Injectable } from '@angular/core';
import { Emitter, Emittable } from '@ngxs-labs/emitter';
import { ApplicationResponse } from '../dtos/ApplicationResponse';
import { BankInfoRequest } from '../dtos/BankInfoRequest';
import { BasicInfoRequest } from '../dtos/BasicInfoRequest';
import { ContactInfoRequest } from '../dtos/ContactInfoRequest';
import { FinancialInfoRequest } from '../dtos/FinancialInfoRequest';
import { LegitimationInfoRequest } from '../dtos/LegitimationInfoRequest';
import { PaymentInfoRequest } from '../dtos/PaymentInfoRequest';
import { PersonalInfoRequest } from '../dtos/PersonalInfoRequest';
import { ProductResponse } from '../dtos/ProductResponse';
import { ServiceInfoRequest } from '../dtos/ServiceInfoRequest';
import { BankInfoState, EmptyBankInfoRequest } from '../states/BankInfoState';
import {
  BasicInfoState,
  EmptyBasicInfoRequest,
} from '../states/BasicInfoState';
import {
  ContactInfoState,
  EmptyContactInfoRequest,
} from '../states/ContactInfoState';
import {
  CurrentApplicationState,
  EmptyApplicationResponse,
} from '../states/CurrentApplicationState';
import {
  EmptyFinancialInfoRequest,
  FinancialInfoState,
} from '../states/FinancialInfoState';
import {
  EmptyLegitimationInfoRequest,
  LegitimationInfoState,
} from '../states/LegitimationInfoState';
import {
  EmptyPaymentInfoRequest,
  PaymentInfoState,
} from '../states/PaymentInfoState';
import {
  EmptyPersonalInfoRequest,
  PersonalInfoState,
} from '../states/PersonalInfoState';
import {
  EmptySelectedProductRequest,
  SelectedProductState,
} from '../states/SelectedProductState';
import {
  EmptyServiceInfoRequest,
  ServiceInfoState,
} from '../states/ServiceInfoState';
import {
  CurrentErrorState,
  EmptyErrorModel,
  ErrorModel,
} from '../states/CurrentErrorState';
import {
  AppState,
  AppStateModel,
  InitialAppStateModel,
} from '../states/AppState';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  constructor() {}

  @Emitter(BankInfoState.updateBankInfo)
  updateBankInfo$!: Emittable<BankInfoRequest>;

  @Emitter(BasicInfoState.updateBasicInfo)
  updateBasicInfo$!: Emittable<BasicInfoRequest>;

  @Emitter(ContactInfoState.updateContactInfo)
  updateContactInfo$!: Emittable<ContactInfoRequest>;

  @Emitter(FinancialInfoState.updateFinancialInfo)
  updateFinancialInfo$!: Emittable<FinancialInfoRequest>;

  @Emitter(LegitimationInfoState.updateLegitimationInfo)
  updateLegitimationInfo$!: Emittable<LegitimationInfoRequest>;

  @Emitter(PersonalInfoState.updatePersonalInfo)
  updatePersonalInfo$!: Emittable<PersonalInfoRequest>;

  @Emitter(CurrentApplicationState.updateCurrentApplication)
  updateCurrentApplication$!: Emittable<ApplicationResponse>;

  @Emitter(SelectedProductState.updateSelectedProduct)
  updateSelectedProduct$!: Emittable<ProductResponse>;

  @Emitter(PaymentInfoState.updatePaymentInfo)
  updatePaymentInfo$!: Emittable<PaymentInfoRequest>;

  @Emitter(ServiceInfoState.updateServiceInfo)
  updateServiceInfo$!: Emittable<ServiceInfoRequest>;

  @Emitter(CurrentErrorState.updateCurrentError)
  updateCurrentError$!: Emittable<ErrorModel>;

  @Emitter(AppState.updateAppState)
  updateAppState$!: Emittable<AppStateModel>;

  clearAllState(): void {
    this.updateBankInfo$.emit(EmptyBankInfoRequest);
    this.updateBasicInfo$.emit(EmptyBasicInfoRequest);
    this.updateContactInfo$.emit(EmptyContactInfoRequest);
    this.updateFinancialInfo$.emit(EmptyFinancialInfoRequest);
    this.updateLegitimationInfo$.emit(EmptyLegitimationInfoRequest);
    this.updatePersonalInfo$.emit(EmptyPersonalInfoRequest);
    this.updateCurrentApplication$.emit(EmptyApplicationResponse);
    this.updateSelectedProduct$.emit(EmptySelectedProductRequest);
    this.updatePaymentInfo$.emit(EmptyPaymentInfoRequest);
    this.updateServiceInfo$.emit(EmptyServiceInfoRequest);
    this.updateCurrentError$.emit(EmptyErrorModel);
    this.updateAppState$.emit(InitialAppStateModel);
  }
}
