import { Pipe, PipeTransform } from '@angular/core';
import { PaybackMethods } from '../dtos/PaybackMethod';

@Pipe({
  name: 'paybackMethod'
})
export class PaybackMethodPipe implements PipeTransform {

  transform(value: string | undefined): string | null {

    if (value === undefined || value === null) {
      return null;
    }

    for (let i = 0; i < PaybackMethods.length; i++) {
      if (PaybackMethods[i].value === value) {
        return PaybackMethods[i].label;
      }
    }
    return null;
  }

}
