//[ HR, RGR, AR, KZLR, KOMMR, OEKR, MEDR, OMEDR, VETR, TR, BARHC, BERHC, FORHC, SR, OSR, STR, OSTR, UNIV_PROF, KSAENG, KSCHAUSP, PROF ]

export enum OccupationTitle {
  HR = 'HR',
  RGR = 'RGR',
  AR = 'AR',
  KZLR = 'KZLR',
  KOMMR = 'KOMMR',
  OEKR = 'OEKR',
  MEDR = 'MEDR',
  OMEDR = 'OMEDR',
  VETR = 'VETR',
  TR = 'TR',
  BARHC = 'BARHC',
  BERHC = 'BERHC',
  FORHC = 'FORHC',
  SR = 'SR',
  OSR = 'OSR',
  STR = 'STR',
  OSTR = 'OSTR',
  UNIV_PROF = 'UNIV_PROF',
  KSAENG = 'KSAENG',
  KSCHAUSP = 'KSCHAUSP',
  PROF = 'PROF',
}

export const OccupationTitles = [
  { value: 'HR', label: 'Hofrätin, Hofrat (HR)' },
  { value: 'RGR', label: 'Regierungsrätin, Regierungsrat (RgR)' },
  { value: 'AR', label: 'Amtsrätin, Amtsrat (AR)' },
  { value: 'KZLR', label: 'Kanzleirätin, Kanzleirat (KzlR)' },
  { value: 'KOMMR', label: 'Kommerzialrätin, Kommerzialrat (KommR)' },
  { value: 'OEKR', label: 'Ökonomierätin, Ökonomierat (ÖkR)' },
  { value: 'MEDR', label: 'Medizinalrätin, Medizinalrat (MedR)' },
  { value: 'OMEDR', label: 'Obermedizinalrätin, Obermedizinalrat (OMedR)' },
  { value: 'VETR', label: 'Veterinärrätin, Veterinärrat (VetR)' },
  { value: 'TR', label: 'Technische Rätin, Technischer Rat (TR)' },
  {
    value: 'BARHC',
    label: 'Baurätin honoris causa (h.c.), Baurat honoris causa (h.c.)',
  },
  {
    value: 'BERHC',
    label: 'Bergrätin honoris causa (h.c.), Bergrat honoris causa (h.c.)',
  },
  {
    value: 'FORHC',
    label: 'Forsträtin honoris causa (h.c.), Forstrat honoris causa (h.c.)',
  },
  { value: 'SR', label: 'Schulrätin, Schulrat (SR)' },
  { value: 'OSR', label: 'Oberschulrätin, Oberschulrat (OSR)' },
  { value: 'STR', label: 'Studienrätin, Studienrat (StR)' },
  { value: 'OSTR', label: 'Oberstudienrätin, Oberstudienrat (OStR)' },
  {
    value: 'UNIV_PROF',
    label: 'Universitätsprofessorin, Universitätsprofessor (UnivProf)',
  },
  { value: 'KSAENG', label: 'Kammersängerin, Kammersänger (KSäng)' },
  {
    value: 'KSCHAUSP',
    label: 'Kammerschauspielerin, Kammerschauspieler (KSchausp)',
  },
  { value: 'PROF', label: 'Professorin, Professor (Prof)' },
];
