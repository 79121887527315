import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalLoaderService } from 'src/app/services/global-loader.service';

@Component({
  selector: 'app-global-loader',
  templateUrl: './global-loader.component.html',
  styleUrls: ['./global-loader.component.scss']
})
export class GlobalLoaderComponent implements OnInit {
  isLoading$: Observable<boolean> = this.globalLoaderService.isLoading$();

  constructor(private globalLoaderService: GlobalLoaderService) {

  }

  ngOnInit() {
  }
}
