<div class="mt-5 mw600">
  <div *ngIf="backendError" class="alert alert-danger" role="alert">
    Es ist ein Fehler aufgetreten
  </div>
  <form [formGroup]="basicInfoForm" (ngSubmit)="onSubmit()">
    <!-- <div class="mb-3 form-check">
      <label for="ownDemandControl" class="form-check-label"
        >Auf eigene Rechnung eröffnen: *</label
      >
      <input
        type="checkbox"
        class="form-check-input"
        id="ownDemandControl"
        formControlName="ownDemand"
      />
      <div
        *ngIf="basicInfoForm.controls['ownDemand'].errors"
        class="alert alert-danger"
        role="alert"
      >
        Das Produkt muss auf eigene Rechnung eröffnet werden
      </div>
    </div> -->

    <div class="mb-3">
      <b>Angabe gemäß Finanzmarkt-Geldwäschegesetz (FM-GwG)</b><br />
      Als Finanzinstitut untersteht PayLife den Pflichten des
      Finanzmarkt-Geldwäschegesetzes (FM-GwG). Im FM-GwG sind Sorgfaltspflichten
      vor Aufnahme der Geschäftsbeziehung vorgesehen, die Banken zu erfüllen
      haben. Eine allfällige Änderung während aufrechter Geschäftsbeziehung ist
      gemäß FM-GwG unverzüglich bekanntzugeben.
    </div>
    <div class="mb-2"><b>Ich beantrage die Kreditkarte *</b></div>

    <div class="form-check mb-3">
      <input
        class="form-check-input"
        type="radio"
        formControlName="ownDemand"
        id="ownDemandControl"
        [value]="true"
      />
      <label class="form-check-label" for="ownDemandControl">
        auf eigene Rechnung eröffnen
      </label>
    </div>
    <div class="form-check mb-3">
      <input
        class="form-check-input"
        type="radio"
        formControlName="ownDemand"
        id="foreignDemandControl"
        [value]="false"
      />
      <label class="form-check-label" for="foreignDemandControl">
        auf fremde Rechnung eröffnen
      </label>
    </div>

    <div
      *ngIf="
        basicInfoForm.controls['ownDemand'].value != null &&
        basicInfoForm.controls['ownDemand'].value === false
      "
      class="alert alert-danger"
      role="alert"
    >
      Das Produkt muss auf eigene Rechnung eröffnet werden
    </div>
    <div class="border border-primary p-3 mb-3 rounded border-3">
      <div class="mb-3">
        <b>Verwendungszweck der Kreditkarte: </b> Mehrfachnennungen möglich *
      </div>
      <div class="mb-3 form-check">
        <label for="cardPaymentControl" class="form-check-label"
          >Bargeldloses Bezahlen von Waren und Dienstleistungen</label
        >
        <input
          type="checkbox"
          class="form-check-input"
          id="cardPaymentControl"
          formControlName="cardPayment"
        />
      </div>

      <div class="mb-3 form-check">
        <label for="internetPaymentControl" class="form-check-label"
          >Bezahlung von Internetkäufen</label
        >
        <input
          type="checkbox"
          class="form-check-input"
          id="internetPaymentControl"
          formControlName="internetPayment"
        />
      </div>

      <div class="mb-3 form-check">
        <label for="drawCashControl" class="form-check-label"
          >Behebung von Bargeld</label
        >
        <input
          type="checkbox"
          class="form-check-input"
          id="drawCashControl"
          formControlName="drawCash"
        />
      </div>
    </div>

    <div class="mb-3">
      <div class="row form-label">
        <div class="col">
          <label for="pepControl"
            >Sind Sie eine politisch exponierte Person? *</label
          >
        </div>
        <div class="col-auto text-end">
          <svg
            (click)="showPepInfo = !showPepInfo"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clip-path="url(#clip0_15_702)">
              <path
                d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"
                fill="#000066"
              />
            </g>
            <defs>
              <clipPath id="clip0_15_702">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>

      <ng-select
        id="pepControl"
        placeholder="Bitte den PEP Status auswählen"
        [items]="peps"
        bindLabel="label"
        bindValue="value"
        formControlName="pep"
      >
      </ng-select>
      <div
        *ngIf="
          basicInfoForm.controls['pep'].errors &&
          basicInfoForm.controls['pep'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte den PEP Status auswählen
      </div>
    </div>
    <div *ngIf="showPepInfo" class="alert alert-info" role="alert">
      Im Sinne des §2 Z6 bis 8 FM-GwG handelt es sich bei Politisch Exponierten
      Personen ("PEP's") um diejenigen natürlichen Personen, die wichtige
      öffentliche Ämter im In- und Ausland ausüben oder bis vor einem Jahr
      ausgeübt haben, und deren unmittelbare Familienmitglieder oder ihnen
      bekanntermaßen nahe stehende Personen. <br /><br />
      1. Personen, die bedeutende öffentliche Funktionen erfüllen, teilen sich
      in acht Unterkategorien auf:
      <ul>
        <li>
          Staatsoberhäupter, Regierungschefs, Minister und deren Stellvertreter
          und Staatssekretäre (im Inland insb.: Bundespräsident, -kanzler,
          Mitglieder der Bundesregierung und Landesregierungen)
        </li>
        <li>
          Parlamentsmitglieder (im Inland insb.: Abgeordnete d. Nationalrates u.
          Bundesrates)
        </li>
        <li>
          Mitglieder d. Führungsgremien polit. Parteien (im Inland insb.: jene,
          die im Nationalrat vertreten sind)
        </li>
        <li>
          Mitglieder von obersten Gerichten, Verfassungsgerichten oder sonstigen
          hochrangigen Institutionen der Justiz (im Inland insb.: Richter des
          OGH, VfGH, VwGH)
        </li>
        <li>
          Mitglieder v. Rechnungshöfen/Leitungsorgane v. Zentralbanken (im
          Inland insb.: Präsident des BRH, Direktoren d. LRH, Mitglieder d.
          Direktoriums der OeNB)
        </li>
        <li>
          Botschafter, Geschäftsträger oder hochrangige Offiziere der
          Streitkräfte (im Inland insb.: Militärpersonen ab Dienstgrad
          Generalleutnant, z.B. Generalstabschef/Stv., militärische
          Sektionsleiter, Streitkräftekommandant, Kommandant d.
          Landesverteidigungsakademie)
        </li>
        <li>
          Mitglieder der Verwaltungs-, Leitungs- oder Aufsichtsorgane
          staatlicher Unternehmen
        </li>
        <li>
          Direktoren/Stv. und Mitglieder d. Leitungsorgane bei einer
          internationalen Organisation (z.B. UNO, OECD, OPEC, Weltbank)
        </li>
      </ul>

      2. Als unmittelbare Familienmitglieder werden Ehepartner, Lebenspartner
      und Lebensgefährten, Kinder (einschließlich Wahl- und Pflegekinder) und
      deren Ehepartner/Lebenspartner/-gefährten und die Eltern der politisch
      exponierten Person betrachtet. <br /><br />
      3. Bei Personen, die als enge Mitarbeiter/Partner bezeichnet werden, gibt
      es zwei Unterkategorien:
      <ul>
        <li>
          Natürliche Personen, die gemeinsam mit einer politisch exponierten
          Person wirtsch. Eigentümer von jur. Personen/Rechtsvereinbarungen sind
          oder sonstige Geschäftsbeziehungen mit einer politisch exponierten
          Person haben.
        </li>
        <li>
          Natürliche Personen, die alleinige wirtsch. Eigentümer einer
          rechtlichen Einheit (dazu zählen auch Unternehmen, Fonds etc.) sind,
          von der eine politisch exponierte Person wirtschaftlich profitiert.
        </li>
      </ul>
    </div>

    <div *ngIf="showPep" class="mb-3">
      <label for="activityControl" class="form-label">Tätigkeit: *</label>
      <input
        type="text"
        class="form-control"
        id="activityControl"
        placeholder="Bitte die Tätigkeit eingeben"
        formControlName="activity"
      />
      <div
        *ngIf="
          basicInfoForm.controls['activity'].errors &&
          basicInfoForm.controls['activity'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte eine gültige Tätigkeit eingeben
      </div>
    </div>
    <div *ngIf="showPep" class="mb-3">
      <label for="politicalFunctionControl" class="form-label"
        >Funktion: *</label
      >
      <ng-select
        id="politicalFunctionControl"
        placeholder="Bitte die Funktion auswählen"
        [items]="politicalFunctions"
        bindLabel="label"
        bindValue="value"
        formControlName="politicalFunction"
      >
      </ng-select>
      <div
        *ngIf="
          basicInfoForm.controls['politicalFunction'].errors &&
          basicInfoForm.controls['politicalFunction'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte eine Funktion auswählen
      </div>
    </div>
    <div *ngIf="showPepRelative" class="mb-3">
      <label for="relationshipControl" class="form-label">Beziehung: *</label>
      <input
        type="text"
        class="form-control"
        id="relationshipControl"
        placeholder="Bitte die Beziehung eingeben"
        formControlName="relationship"
      />
      <div
        *ngIf="
          basicInfoForm.controls['relationship'].errors &&
          basicInfoForm.controls['relationship'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte eine gültige Beziehung eingeben
      </div>
    </div>
    <div *ngIf="showPepRelative" class="mb-3">
      <label for="firstNameControl" class="form-label">Vorname: *</label>
      <input
        type="text"
        class="form-control"
        id="firstNameControl"
        placeholder="Bitte den Vornamen eingeben"
        formControlName="firstName"
      />
      <div
        *ngIf="
          basicInfoForm.controls['firstName'].errors &&
          basicInfoForm.controls['firstName'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte einen gültigen Vornamen eingeben
      </div>
    </div>
    <div *ngIf="showPepRelative" class="mb-3">
      <label for="lastNameControl" class="form-label">Nachname: *</label>
      <input
        type="text"
        class="form-control"
        id="lastNameControl"
        placeholder="Bitte den Nachnamen eingeben"
        formControlName="lastName"
      />
      <div
        *ngIf="
          basicInfoForm.controls['lastName'].errors &&
          basicInfoForm.controls['lastName'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte einen gültigen Nachnamen eingeben
      </div>
    </div>
    <div *ngIf="showPepRelative" class="mb-3">
      <label for="birthdayControl" class="form-label">Geburtsdatum: *</label>
      <input
        type="date"
        class="form-control"
        id="birthdayControl"
        formControlName="birthday"
        max="{{ latestPossibleBirthday | date : 'yyyy-MM-dd' }}"
        min="{{ earliestDate | date : 'yyyy-MM-dd' }}"
      />
      <div
        *ngIf="
          basicInfoForm.controls['birthday'].errors &&
          basicInfoForm.controls['birthday'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte ein gültiges Datum eingeben
      </div>
    </div>
    <div *ngIf="showPepRelative" class="mb-3">
      <label for="nationalityControl" class="form-label"
        >Staatsangehörigkeit: *</label
      >
      <ng-select
        id="nationalityControl"
        placeholder="Bitte die Staatsangehörigkeit auswählen"
        [items]="countries"
        bindLabel="country"
        bindValue="countryCode"
        formControlName="nationality"
      >
      </ng-select>
      <div
        *ngIf="
          basicInfoForm.controls['nationality'].errors &&
          basicInfoForm.controls['nationality'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte eine Staatsangehörigkeit auswählen
      </div>
    </div>

    <div class="container-fluid">
      <div class="row p-0">
        <div class="col text-start p-0">
          <button type="button" (click)="back()" class="btn btn-primary">
            Zurück
          </button>
        </div>
        <div class="col text-end p-0">
          <button
            type="submit"
            [disabled]="basicInfoForm.invalid"
            class="btn btn-primary"
          >
            {{ submitText }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
