<div class="mt-5 mw600">
  <div *ngIf="backendError" class="alert alert-danger" role="alert">
    Es ist ein Fehler aufgetreten
  </div>
  <form [formGroup]="uploadForm" (ngSubmit)="onSubmit()">
    <div class="mb-3"><b>Dokumente hochladen: </b></div>
    <div class="mb-3">
      <label for="passportControl" class="form-label">{{ label }}: *</label>
      <input
        type="file"
        class="form-control"
        id="passportControl"
        placeholder="Bitte die Ausweise auswählen"
        formControlName="passport"
        multiple
        (change)="onPassportFileChange($event)"
      />
      <div
        *ngIf="
          (uploadForm.controls['passport'].errors && passportChanged) ||
          invalidPassportFileType
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte einen gültigen Ausweis auswählen (pdf, png, jpg, jpeg)
      </div>
    </div>
    <!--     <div *ngIf="showNonAtControls" class="mb-3">
      <label for="currentRegistrationControl" class="form-label"
        >Meldezettel: *</label
      >
      <input
        type="file"
        class="form-control"
        id="currentRegistrationControl"
        placeholder="Bitte den Meldezettel auswählen"
        formControlName="currentRegistration"
        (change)="onCurrentRegistrationFileChange($event)"
      />
      <div
        *ngIf="
          (uploadForm.controls['currentRegistration'].errors &&
            uploadForm.controls['currentRegistration'].touched) ||
          invalidCurrentRegistrationFileType
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte einen gültigen Meldezettel auswählen (pdf, png, jpg, jpeg)
      </div>
    </div> -->
    <div *ngIf="showNonEuControls" class="mb-3">
      <label for="residencePermitControl" class="form-label"
        >Aufenthaltstitel: *</label
      >
      <input
        type="file"
        class="form-control"
        id="residencePermitControl"
        placeholder="Bitte den Aufenthaltstitel auswählen"
        formControlName="residencePermit"
        (change)="onResidencePermitFileChange($event)"
      />
      <div
        *ngIf="
          (uploadForm.controls['residencePermit'].errors && residenceChanged) ||
          invalidResidencePermitFileType
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte einen gültigen Aufenthaltstitel auswählen (pdf, png, jpg, jpeg)
      </div>
    </div>

    <div *ngIf="showBankGuarantee" class="mb-3">
      <label class="form-label" for="bankGuaranteeControl"
        >Garantieerklärung der Bank:</label
      >

      <div class="alert alert-info" role="alert">
        Leider erfüllt der Antragsteller nicht alle Vorgaben der aktuell
        gültigen Vergaberichtlinien, weswegen die Abgabe einer Garantie
        erforderlich ist. Hier finden Sie die
        <a
          target="_blank"
          href="https://www.paylife.at/linkableblob/paylife/535284/eae12f7217d5d8c194c0d53da1d6f6fc/kk-
          garantieerklaerung-2022-data.pdf"
          >Garantieerklärung</a
        >. Die Vergaberichtlinien finden Sie unter folgendem
        <a
          href="../../../../assets/files/Vergaberichtlinien-Privat-u-Business-02_2024.pdf"
          target="_blank"
          >Link</a
        >.<br /><br />
        <b
          >Sie möchten die Garantieerklärung zu einem späteren Zeitpunkt
          hochladen?</b
        ><br />Kein Problem, nutzen Sie den Wiederaufsatzlink, ohne die bereits
        eingegebenen Daten zu verlieren! Sie erhalten für jeden Kunden ein
        E-Mail mit dem Link zum Fortsetzen des Kartenauftrags.
      </div>
      <input
        type="file"
        class="form-control"
        id="bankGuaranteeControl"
        placeholder="Bitte die Garantieerklärung auswählen"
        formControlName="bankGuarantee"
        (change)="onBankGuaranteeFileChange($event)"
      />
      <div
        *ngIf="
          (uploadForm.controls['bankGuarantee'].errors && guaranteeChanged) ||
          invalidBankGuaranteeFileType
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte eine gültige Garantieerklärung auswählen (pdf, png, jpg, jpeg)
      </div>
    </div>
    <div class="alert alert-info mb-3" role="alert">
      <small><b>Maximale Größe:</b> 10MB</small> <br />
      <small><b>Mögliche Dateiformate:</b> PDF, PNG, JPG, JPEG</small>
    </div>
    <div class="container-fluid">
      <div class="row p-0">
        <div class="col text-start p-0">
          <button type="button" (click)="back()" class="btn btn-primary">
            Zurück
          </button>
        </div>
        <div class="col text-end p-0">
          <button
            type="submit"
            [disabled]="
              uploadForm.invalid ||
              invalidPassportFileType ||
              invalidCurrentRegistrationFileType ||
              invalidResidencePermitFileType ||
              invalidBankGuaranteeFileType
            "
            class="btn btn-primary"
          >
            Weiter
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
