import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PersonalInfoComponent } from './components/personal-info/personal-info.component';
import { ContactInfoComponent } from './components/contact-info/contact-info.component';
import { LegitimationInfoComponent } from './components/legitimation-info/legitimation-info.component';
import { BankInfoComponent } from './components/bank-info/bank-info.component';
import { FinancialInfoComponent } from './components/financial-info/financial-info.component';
import { ProductSelectionComponent } from './components/product-selection/product-selection.component';
import { BasicInfoComponent } from './components/basic-info/basic-info.component';
import { ServiceInfoComponent } from './components/service-info/service-info.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { SummaryComponent } from './components/summary/summary.component';
import { SigningComponent } from './components/signing/signing.component';
import { HomeComponent } from './components/home/home.component';
import { ResultComponent } from './components/result/result.component';
import { AuthGuard } from './auth.guard';
import { GuaranteeUploadComponent } from './components/guarantee-upload/guarantee-upload.component';

const routes: Routes = [
  {
    path: 'guarantee-upload',
    component: GuaranteeUploadComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'product-selection',
    component: ProductSelectionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'personal-info',
    component: PersonalInfoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'contact-info',
    component: ContactInfoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'legitimation-info',
    component: LegitimationInfoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'bank-info',
    component: BankInfoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'financial-info',
    component: FinancialInfoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'basic-info',
    component: BasicInfoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'service-info',
    component: ServiceInfoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'file-upload',
    component: FileUploadComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'summary',
    component: SummaryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'signing',
    component: SigningComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'result',
    component: ResultComponent,
    canActivate: [AuthGuard],
  },
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: '**', component: HomeComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
