import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Emitter, Emittable } from '@ngxs-labs/emitter';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ApplicationResponse } from 'src/app/dtos/ApplicationResponse';
import { SignApplicationRequest } from 'src/app/dtos/SignApplicationRequest';
import { ApiConnectorService } from 'src/app/services/api-connector.service';
import { CurrentApplicationState } from 'src/app/states/CurrentApplicationState';
import {
  CurrentErrorState,
  ErrorModel,
} from 'src/app/states/CurrentErrorState';
import { ContactInfoState } from 'src/app/states/ContactInfoState';
import { ContactInfoRequest } from 'src/app/dtos/ContactInfoRequest';
import { ServiceInfoState } from 'src/app/states/ServiceInfoState';
import { ServiceInfoRequest } from 'src/app/dtos/ServiceInfoRequest';
import { PaymentInfoState } from 'src/app/states/PaymentInfoState';
import { PaymentInfoRequest } from 'src/app/dtos/PaymentInfoRequest';
import { ProductResponse } from 'src/app/dtos/ProductResponse';
import { SelectedProductState } from 'src/app/states/SelectedProductState';
import { AppState, AppStateModel, Screen } from 'src/app/states/AppState';

const errorMap = new Map([
  ['Code is expired', 'Der Code ist bereits abgelaufen'],
  ['To many failed Attempts', 'Zu viele Fehlversuche'],
  ['Invalid Code', 'Code ungültig'],
]);

@Component({
  selector: 'app-signing',
  templateUrl: './signing.component.html',
  styleUrls: ['./signing.component.scss'],
})
export class SigningComponent implements OnInit {
  constructor(
    private api: ApiConnectorService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  backendError: boolean = false;
  errorMessage: string = '';

  @Select(CurrentApplicationState.getCurrentApplication)
  getCurrentApplication$!: Observable<ApplicationResponse>;
  currentApplication: ApplicationResponse = {};

  @Select(SelectedProductState.getSelectedProduct)
  getSelectedProduct$!: Observable<ProductResponse>;
  selectedProduct: ProductResponse = {};

  @Select(ContactInfoState.getContactInfo)
  getContactInfo$!: Observable<ContactInfoRequest>;
  contactInfo: ContactInfoRequest = {};

  @Select(ServiceInfoState.getServiceInfo)
  getServiceInfo$!: Observable<ServiceInfoRequest>;
  serviceInfo: ServiceInfoRequest = {};

  @Select(PaymentInfoState.getPaymentInfo)
  getPaymentInfo$!: Observable<PaymentInfoRequest>;
  paymentInfo: PaymentInfoRequest = {};

  @Emitter(CurrentApplicationState.updateCurrentApplication)
  updateCurrentApplication$!: Emittable<ApplicationResponse>;

  @Select(CurrentErrorState.getCurrentError)
  getCurrentError$!: Observable<ErrorModel>;
  currentError: ErrorModel = {};

  @Emitter(CurrentErrorState.updateCurrentError)
  updateCurrentError$!: Emittable<ErrorModel>;

  @Emitter(AppState.updateAppState)
  updateAppState$!: Emittable<AppStateModel>;

  @Select(AppState.getAppState)
  getAppState$!: Observable<AppStateModel>;
  appState: AppStateModel = {};

  signingForm: FormGroup = {} as FormGroup;
  signApplicationRequest: SignApplicationRequest = {};

  readMore: boolean = false;
  buttonText: string = 'Mehr lesen';

  ngOnInit(): void {
    this.getAppState$.subscribe((value) => (this.appState = value));
    this.appState.currentScreen = Screen.SIGN;
    this.updateAppState$.emit(this.appState);

    this.getSelectedProduct$.subscribe(
      (value) => (this.selectedProduct = value)
    );

    this.getPaymentInfo$.subscribe((value) => (this.paymentInfo = value));

    this.getServiceInfo$.subscribe((value) => (this.serviceInfo = value));

    this.getCurrentApplication$.subscribe(
      (value) => (this.currentApplication = value)
    );

    this.getContactInfo$.subscribe((value) => (this.contactInfo = value));

    this.getCurrentError$.subscribe((value) => (this.currentError = value));

    this.signingForm = this.formBuilder.group({
      code: [null, [Validators.required, Validators.pattern(`^[0-9]{5}$`)]],
      acceptMTAN: [false, [Validators.requiredTrue]],
      acceotRemote: [false, [Validators.requiredTrue]],
    });

    const con = document.getElementById('contract') as HTMLAnchorElement;
    this.api
      .getContractPreview(this.currentApplication.applicationId!)
      .subscribe({
        next: (blob) => {
          con.href = URL.createObjectURL(blob);
        },
        error: (error) => {
          this.backendError = true;
          this.errorMessage = 'Es ist ein Fehler aufgetreten';
          console.log(error);
        },
      });
  }

  toggle(): void {
    if (this.readMore) {
      this.readMore = false;
      this.buttonText = 'Mehr lesen';
    } else {
      this.readMore = true;
      this.buttonText = 'Weniger lesen';
    }
  }

  newCode(): void {
    this.api
      .confirmApplication(this.currentApplication.applicationId!)
      .subscribe({
        next: (response: ApplicationResponse) => {
          this.updateCurrentApplication$.emit(response);
          this.backendError = false;
        },
        error: (error) => {
          this.backendError = true;
          this.errorMessage = 'Es ist ein Fehler aufgetreten';
          console.log(error);
        },
      });
  }

  onSubmit(): void {
    this.signApplicationRequest = {
      code: this.signingForm.controls['code'].value,
    };

    this.api
      .signApplication(
        this.currentApplication.applicationId!,
        this.signApplicationRequest
      )
      .subscribe({
        next: (response: ApplicationResponse) => {
          this.updateCurrentApplication$.emit(response);
          this.router.navigateByUrl('result');
        },
        error: (error) => {
          this.backendError = true;
          console.log(error);
          const message = error.headers.get('x-bawagpsk-frontend-error');
          console.log(message);

          if (errorMap.has(message)) {
            this.errorMessage = errorMap.get(message)!;
          } else if (message === 'MAIL_SERVICE_NOT_AVAILABLE') {
            this.currentError.mailServiceFailed = true;
            this.updateCurrentError$.emit(this.currentError);
            this.router.navigateByUrl('result');
          } else {
            this.errorMessage = 'Es ist ein Fehler aufgetreten';
          }
        },
      });
  }
}
