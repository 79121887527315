import { Injectable } from '@angular/core';
import { Selector, State, StateContext } from '@ngxs/store';
import { EmitterAction, Receiver } from '@ngxs-labs/emitter';
import { PaymentInfoRequest } from '../dtos/PaymentInfoRequest';


export const EmptyPaymentInfoRequest = {
  paybackMethod: undefined,
  paybackPlan: undefined,
  paybackAmount: undefined,
  paybackPercentage: undefined,
  livingCosts: undefined,
  monthlyCreditLimit: undefined,
  monthlyLeasingRate: undefined,
}

@State<PaymentInfoRequest>({
  name: 'paymentInfo',
  defaults: EmptyPaymentInfoRequest,

})
@Injectable()
export class PaymentInfoState {
  @Selector()
  public static getPaymentInfo(state: PaymentInfoRequest): PaymentInfoRequest {
    return state;
  }
  @Receiver()
  public static updatePaymentInfo(
    { getState, patchState }: StateContext<PaymentInfoRequest>,
    { payload }: EmitterAction<PaymentInfoRequest>
  ) {
    const state = getState();
    patchState({
      ...state,
      ...payload,
    });
  }
}
