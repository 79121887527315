import { Pipe, PipeTransform } from '@angular/core';
import { PostposedAcademicTitles } from '../dtos/PostposedAcademicTtile';

@Pipe({
  name: 'postposedAcademicTitle',
})
export class PostposedAcademicTitlePipe implements PipeTransform {
  transform(value: string | undefined): string | null {
    if (value === undefined || value === null) {
      return null;
    }

    for (let i = 0; i < PostposedAcademicTitles.length; i++) {
      if (PostposedAcademicTitles[i].value === value) {
        return PostposedAcademicTitles[i].label;
      }
    }
    return null;
  }
}
