<div class="mt-5 mw600">
  <div *ngIf="error" class="alert alert-danger" role="alert">
    {{ errorMessage }}
  </div>
  <div *ngIf="red" class="alert alert-danger" role="alert">
    Vielen Dank für Ihren Kartenauftrag. Es kann leider keine Kreditkarte
    ausgestellt werden, da die Vergaberichtlinien nicht erfüllt wurden.
  </div>
  <div *ngIf="yellow" class="alert alert-warning" role="alert">
    Vielen Dank für Ihren Kartenauftrag. Die Daten werden geprüft. Wenn alle
    Vorgaben erfüllt sind, wird die Kreditkarte ausgestellt. Sie erhalten in den
    nächsten Tagen von uns dazu eine entsprechende Rückmeldung.
  </div>
  <div *ngIf="green" class="alert alert-success" role="alert">
    Vielen Dank für Ihren Kartenauftrag. Die Daten wurden erfasst. Es wird in
    wenigen Tagen eine PayLife Kreditkarte ausgestellt.
  </div>
  <div *ngIf="waiting" class="alert alert-warning" role="alert">
    Vielen Dank für Ihren Kartenauftrag. Für die weitere Bearbeitung ist der
    Upload der Garantieerklärung unbedingt notwendig. Sie können die Garantie
    über den Wiederaufsatzlink hochladen, den wir bereits an Ihre E-Mail Adresse
    geschickt haben.
  </div>
  <button (click)="finish()" class="btn btn-primary mt-3">Fertig</button>
</div>
