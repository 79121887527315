import { Pipe, PipeTransform } from '@angular/core';
import { PoliticalFunctions } from '../dtos/PoliticalFunction';

@Pipe({
  name: 'politicalFunction'
})
export class PoliticalFunctionPipe implements PipeTransform {

  transform(value: string | undefined): string | null {

    if (value === undefined || value === null) {
      return null;
    }

    for (let i = 0; i < PoliticalFunctions.length; i++) {
      if (PoliticalFunctions[i].value === value) {
        return PoliticalFunctions[i].label;
      }
    }
    return null;
  }

}
