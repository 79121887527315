import { Injectable } from '@angular/core';
import { Selector, State, StateContext } from '@ngxs/store';
import { EmitterAction, Receiver } from '@ngxs-labs/emitter';
import { ServiceInfoRequest } from '../dtos/ServiceInfoRequest';

export const EmptyServiceInfoRequest = {
  portal: undefined,
  newsletter: undefined,
  agbElectronic: undefined,
  trxNotification: undefined,
  paperBill: undefined,
  //paperPin: undefined,
};

@State<ServiceInfoRequest>({
  name: 'serviceInfo',
  defaults: EmptyServiceInfoRequest,
})
@Injectable()
export class ServiceInfoState {
  @Selector()
  public static getServiceInfo(state: ServiceInfoRequest): ServiceInfoRequest {
    return state;
  }
  @Receiver()
  public static updateServiceInfo(
    { getState, patchState }: StateContext<ServiceInfoRequest>,
    { payload }: EmitterAction<ServiceInfoRequest>
  ) {
    const state = getState();
    patchState({
      ...state,
      ...payload,
    });
  }
}
