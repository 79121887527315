<div class="mt-5 mw600">
  <div *ngIf="backendError" class="alert alert-danger" role="alert">
    {{ errorMessage }}
  </div>

  <div class="border border-primary p-3 mb-3 rounded border-3">
    <div class="mb-3"><b>Prüfen und Unterzeichnen</b></div>
    <div class="alert alert-info mb-3">
      Alle Unterlagen wurden bereits an Ihre E-Mail Adresse gesendet:
      {{ contactInfo.email }}
    </div>
    <div class="alert alert-info mb-3">
      Die mobileTAN wurde an die Mobilnummer gesendet:
      {{ contactInfo.phoneNumber }}
    </div>
    <div class="alert alert-info mb-3" role="alert">
      Die folgenden Dokumente sind zu Ihrere eigenen Sicherheit verschlüsselt.
      Geben Sie als Kennwort bitte Ihr Geburtsdatum im Format "TTMMYYYY" ein.
    </div>
    <div class="mb-3">
      <a
        class="link-offset-2 link-underline link-underline-opacity-0"
        id="contract"
        href=""
        target="_blank"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M4 4C4 2.89543 4.89543 2 6 2H14C14.2652 2 14.5196 2.10536 14.7071 2.29289L19.7071 7.29289C19.8946 7.48043 20 7.73478 20 8V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V4ZM17.5858 8L14 4.41421V8H17.5858ZM12 4L6 4V20H18V10H13C12.4477 10 12 9.55228 12 9V4ZM8 13C8 12.4477 8.44772 12 9 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H9C8.44772 14 8 13.5523 8 13ZM8 17C8 16.4477 8.44772 16 9 16H15C15.5523 16 16 16.4477 16 17C16 17.5523 15.5523 18 15 18H9C8.44772 18 8 17.5523 8 17Z"
            fill="#000066"
          />
        </svg>
        <b>Kreditkartenauftrag.pdf</b></a
      >
    </div>
    <div class="mb-3">
      Die unten angeführten Unterlagen sind diesem Vertrag angeschlossen. Auf
      Anfrage lassen wir Ihnen diese jederzeit zukommen. Folgende
      Geschäftsbedingungen und das Preisblatt in der jeweils zum
      Vertragsabschluss gültigen Fassung werden vereinbart:
    </div>
    <div *ngIf="readMore" class="mb-3">
      <ul>
        <li>Allgemeine Geschäftsbedingungen für PayLife Kreditkarten</li>
        <li>Preisblatt für PayLife Kreditkarten</li>
        <li *ngIf="serviceInfo.trxNotification">
          Besondere Geschäftsbedingungen für den angebotenen Dienst „Info SMS“
          für PayLife Kreditkarten
        </li>
        <li>Besondere Geschäftsbedingungen myPayLife</li>
        <li *ngIf="paymentInfo.paybackMethod === 'PARTIAL_PAYMENT'">
          Besondere Geschäftsbedingungen Teilzahlungsmöglichkeit für die PayLife
          Kreditkarte
        </li>
        <li *ngIf="selectedProduct.productName === 'Classic'">
          Classic Reiseschutz „Europäische Reiseversicherungsbedingungen für
          PayLife Kreditkarten 2022 in der Fassung Besondere Bedingung für die
          PayLife Classic 2022 (ERV-RVB PayLife 2022 idF BB PayLife Classic
          2022)“
        </li>
        <li
          *ngIf="
            selectedProduct.productName === 'Gold' ||
            selectedProduct.productName === 'Platinum' ||
            selectedProduct.productName === 'Black' ||
            selectedProduct.productName === 'Gold Plus'
          "
        >
          Europäische Reiserversicherungsbedingungen für PayLife Kreditkarten
          2022 (ERV-RVB PayLife 2022)
        </li>
        <li
          *ngIf="
            selectedProduct.productName === 'Platinum' ||
            selectedProduct.productName === 'Black' ||
            selectedProduct.productName === 'Gold Plus'
          "
        >
          Besondere Bedingung für die PayLife Reisestornoversicherung 2022
          (ERV-BB PayLife – Storno 2022)
        </li>
        <li
          *ngIf="
            selectedProduct.productName === 'Gold' ||
            selectedProduct.productName === 'Classic' ||
            selectedProduct.productName === 'Platinum' ||
            selectedProduct.productName === 'Black' ||
            selectedProduct.productName === 'Gold Plus'
          "
        >
          Europäische Versicherungsbedingungen für den PayLife Einkaufsschutz
          2022 (ERV-VB PayLife EKS 2022)
        </li>
        <li
          *ngIf="
            selectedProduct.productName === 'Gold' ||
            selectedProduct.productName === 'Platinum' ||
            selectedProduct.productName === 'Black' ||
            selectedProduct.productName === 'Gold Plus'
          "
        >
          Europäische Versicherungsbedingungen für die PayLife Schlüssel-SOS
          2022 (ERV-VB PayLife SSOS 2022)
        </li>
      </ul>
      Neben den zuvor genannten Geschäftsbedingungen und dem Preisblatt erhalte
      ich folgende Informationen in der jeweils bei Vertragsabschluss geltenden
      Fassung:
      <ul>
        <li>
          Informationen gemäß Zahlungsdienstegesetz 2018 (ZaDiG 2018) und gemäß
          Fern-Finanzdienstleistungs-Gesetz (FernFinG)
        </li>
        <li>
          Informationsblatt zum Datenschutz und Information zur
          Datenverarbeitung nach dem Finanzmarkt-Geldwäschegesetz (FM-GWG) und
          Verdachtsdatenbank
        </li>
        <li>Informationsbogen für den Einleger</li>
        <li>Information über die Risiken bei der Verwendung von E-Mails</li>
        <li *ngIf="paymentInfo.paybackMethod === 'PARTIAL_PAYMENT'">
          Europäische Standardinformationen für Kreditierungen nach dem
          Verbraucherkreditgesetz
        </li>
        <li *ngIf="paymentInfo.paybackMethod === 'PARTIAL_PAYMENT'">
          Informationen über Referenzwerte
        </li>
      </ul>
    </div>
    <button (click)="toggle()" class="btn btn-primary btn-sm">
      {{ buttonText }}
    </button>
  </div>

  <div class="border border-primary p-3 mb-3 rounded border-3">
    <div class="mb-3"><b>Jetzt per mobileTAN zeichnen</b></div>

    <form [formGroup]="signingForm" (ngSubmit)="onSubmit()">
      <div class="mb-3 form-check">
        <label for="acceptRemoteControl" class="form-check-label"
          >Ja, ich stimme der Erbringung von Bankgeschäften unter Verwendung von
          Fernkommunikationsmitteln zu und vereinbare diese Art der Erbringung
          mit der BAWAG.</label
        >
        <input
          type="checkbox"
          class="form-check-input"
          id="acceptRemoteControl"
          formControlName="acceotRemote"
        />
      </div>

      <div class="mb-3 form-check">
        <label for="acceptMTANControl" class="form-check-label"
          >Ich bin damit einverstanden, dass mir eine mobileTAN als
          Unterschriftsersatz zur Unterfertigung der oben angeführten Dokumente
          und der im Kreditkartenauftrag enthaltenen Erklärung zur Entbindung
          der BAWAG vom Bankgeheimnis auf meine bekanntgegebene
          Telefonnummer gesendet wird. Mit Eingabe dieser mobileTAN unterzeichne
          ich diese Dokumente sowie die Entbindung vom Bankgeheimnis; damit
          erkläre ich verbindlich mein Einverständnis mit der Geltung und mit
          dem Inhalt dieser Dokumente</label
        >
        <input
          type="checkbox"
          class="form-check-input"
          id="acceptMTANControl"
          formControlName="acceptMTAN"
        />
      </div>

      <div class="mb-3">
        Für Informationen zum Datenschutz klicken Sie auf unsere
        <a
          target="_blank"
          class="link-offset-2 link-underline link-underline-opacity-0"
          href="https://www.paylife.at/linkableblob/-/437898/174b1ecab7c16d5009f7727e3a47e3d2/dsgvo-infoblatt---paylife-aut-pdf-data.pdf"
          ><b>Datenschutzbestimmungen</b></a
        >.
      </div>
      <div class="mb-3">
        <label for="codeControl" class="form-label">mobileTAN:</label>
        <input
          type="password"
          class="form-control"
          id="codeControl"
          placeholder="Bitte den mobileTAN eingeben"
          formControlName="code"
        />
        <div
          *ngIf="
            signingForm.controls['code'].errors &&
            signingForm.controls['code'].touched
          "
          class="alert alert-danger"
          role="alert"
        >
          Bitte einen validen mobileTAN eingeben
        </div>
      </div>

      <div class="container-fluid">
        <div class="row p-0">
          <div class="col text-start p-0">
            <button type="button" (click)="newCode()" class="btn btn-primary">
              Neu anfordern
            </button>
          </div>
          <div class="col text-end p-0">
            <button
              type="submit"
              [disabled]="signingForm.invalid"
              class="btn btn-primary"
            >
              Zeichnen
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
