import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  IdType,
  IdTypesForAT,
  IdTypesForEU,
  IdTypesForNonEU,
} from 'src/app/dtos/IdType';
import { ApiConnectorService } from 'src/app/services/api-connector.service';
import { isAfter, isBefore } from 'src/app/validators/CustomValidator';
import * as countryDataFromJson from '../../../assets/data/country_iso_code.json';
import { PatchApplicationRequest } from 'src/app/dtos/PatchApplicationRequest';
import { LegitimationInfoRequest } from 'src/app/dtos/LegitimationInfoRequest';
import { FormUtils } from 'src/app/utils/FormUtil';
import { CountryUtils } from 'src/app/utils/CountryUtils';
import { LegitimationInfoState } from 'src/app/states/LegitimationInfoState';
import { Emitter, Emittable } from '@ngxs-labs/emitter';
import { PersonalInfoState } from 'src/app/states/PersonalInfoState';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { ApplicationResponse } from 'src/app/dtos/ApplicationResponse';
import { CurrentApplicationState } from 'src/app/states/CurrentApplicationState';
import { AppState, AppStateModel, Screen } from 'src/app/states/AppState';

@Component({
  selector: 'app-legitimation-info',
  templateUrl: './legitimation-info.component.html',
  styleUrls: ['./legitimation-info.component.scss'],
})
export class LegitimationInfoComponent implements OnInit {
  constructor(
    private api: ApiConnectorService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  @Emitter(LegitimationInfoState.updateLegitimationInfo)
  updateLegitimationInfo$!: Emittable<LegitimationInfoRequest>;

  @Select(PersonalInfoState.getCitizenshipt)
  getCitizenship$!: Observable<string>;

  @Select(CurrentApplicationState.getCurrentApplication)
  getCurrentApplication$!: Observable<ApplicationResponse>;
  currentApplication: ApplicationResponse = {};

  @Select(LegitimationInfoState.getLegitimationInfo)
  getLegitimationInfo$!: Observable<LegitimationInfoRequest>;

  @Select(AppState.getAppState)
  getAppState$!: Observable<AppStateModel>;
  appState: AppStateModel = {};
  @Emitter(AppState.updateAppState)
  updateAppState$!: Emittable<AppStateModel>;

  backendError: boolean = false;
  legitimationInfoForm: FormGroup = {} as FormGroup;
  idTypes = IdTypesForAT;
  today: Date = new Date();
  earliestDate: Date = new Date('1900-01-01');
  maxExpirations: Date = new Date();
  countries: any = (countryDataFromJson as any).default;
  patchApplicationRequest: PatchApplicationRequest = {};
  legitimationInforRequest: LegitimationInfoRequest = {};
  citizenship: string = '';
  showIdNumberInfo: boolean = false;
  submitText: string = 'Weiter';
  showExpirationDate: boolean = false;

  ngOnInit(): void {
    this.getAppState$.subscribe((value) => (this.appState = value));
    this.appState.currentScreen = Screen.LEGITIMATION_INFO;
    this.updateAppState$.emit(this.appState);

    if (this.appState.summaryReached) {
      this.submitText = 'Zurück zur Kontrolle';
    }
    this.getCurrentApplication$.subscribe(
      (value) => (this.currentApplication = value)
    );
    this.getCitizenship$.subscribe((value) => (this.citizenship = value));

    this.today.setUTCHours(0);
    this.today.setUTCMinutes(0);
    this.today.setUTCSeconds(0);
    this.today.setUTCMilliseconds(0);
    this.maxExpirations = new Date(this.today.valueOf());
    this.maxExpirations.setFullYear(this.maxExpirations.getFullYear() + 15);

    if (this.citizenship != 'AT') {
      if (CountryUtils.isEuCountry(this.citizenship)) {
        this.idTypes = IdTypesForEU;
      } else {
        this.idTypes = IdTypesForNonEU;
      }
    }

    this.legitimationInfoForm = this.formBuilder.group({
      idType: [null, [Validators.required]],
      idNumber: [
        null,
        [
          Validators.required,
          Validators.pattern(`^[0-9A-Za-zÀ-ž∂\u0370-\u03FF /.'-]+$`),
          Validators.maxLength(15),
          Validators.minLength(8),
        ],
      ],
      issuingAuthority: [
        null,
        [
          Validators.required,
          Validators.pattern(`^[0-9A-Za-zÀ-ž∂\u0370-\u03FF /.'-]+$`),
          Validators.maxLength(30),
        ],
      ],
      expirationDate: [
        null,
        [
          Validators.required,
          isAfter(this.today),
          isBefore(this.maxExpirations),
        ],
      ],
      issuingCountry: [null, [Validators.required]],
      issuingDate: [
        null,
        [Validators.required, isBefore(this.today), isAfter(this.earliestDate)],
      ],
    });

    this.legitimationInfoForm.controls['idType'].valueChanges.subscribe(
      (data) => {
        const expirationDate =
          this.legitimationInfoForm.controls['expirationDate'];

        if (data === IdType.DRIVER_LICENSE) {
          expirationDate.setValue('2033-01-18');
          expirationDate.disable();
          this.showExpirationDate = false;
        } else {
          expirationDate.setValue(null);
          expirationDate.enable();
          this.showExpirationDate = true;
        }

        expirationDate.updateValueAndValidity();
      }
    );

    this.getLegitimationInfo$.subscribe((value) => {
      if (value.idType != undefined) {
        this.legitimationInfoForm.controls['idType'].patchValue(value.idType);
        this.legitimationInfoForm.controls['idType'].disable();
      }
      if (value.idNumber != undefined) {
        this.legitimationInfoForm.controls['idNumber'].patchValue(
          value.idNumber
        );
      }
      if (value.issuingAuthority != undefined) {
        this.legitimationInfoForm.controls['issuingAuthority'].patchValue(
          value.issuingAuthority
        );
      }
      if (value.expirationDate != undefined) {
        this.legitimationInfoForm.controls['expirationDate'].patchValue(
          value.expirationDate
        );
      }
      if (value.issuingCountry != undefined) {
        this.legitimationInfoForm.controls['issuingCountry'].patchValue(
          value.issuingCountry
        );
      }
      if (value.issuingDate != undefined) {
        this.legitimationInfoForm.controls['issuingDate'].patchValue(
          value.issuingDate
        );
      }
    });
  }

  onSubmit(): void {
    this.legitimationInforRequest = {
      idType: this.legitimationInfoForm.controls['idType'].value,
      idNumber: this.legitimationInfoForm.controls['idNumber'].value,
      issuingAuthority:
        this.legitimationInfoForm.controls['issuingAuthority'].value,
      expirationDate: FormUtils.handleClearInput(
        this.legitimationInfoForm.controls['expirationDate'].value
      )!,
      issuingCountry:
        this.legitimationInfoForm.controls['issuingCountry'].value,
      issuingDate: FormUtils.handleClearInput(
        this.legitimationInfoForm.controls['issuingDate'].value
      )!,
    };
    this.patchApplicationRequest = {
      legitimationInfo: this.legitimationInforRequest,
    };

    this.api
      .patchApplication(
        this.patchApplicationRequest,
        this.currentApplication.applicationId!
      )
      .subscribe({
        complete: () => {
          this.updateLegitimationInfo$.emit(this.legitimationInforRequest);
          if (this.appState.summaryReached) {
            this.router.navigateByUrl('summary');
          } else {
            this.router.navigateByUrl('bank-info');
          }
        },
        error: (error) => {
          this.backendError = true;
          console.log(error);
        },
      });
  }

  back(): void {
    this.router.navigateByUrl('contact-info');
  }
}
