import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationResponse } from '../dtos/ApplicationResponse';
import { PatchApplicationRequest } from '../dtos/PatchApplicationRequest';
import { environment } from '../../environments/environment';
import { ProductResponse } from '../dtos/ProductResponse';
import { DocumentType } from '../dtos/DocumentType';
import { UploadFileResponse } from '../dtos/UploadFileResponse';
import { SignApplicationRequest } from '../dtos/SignApplicationRequest';
import { ApplicationDetailResponse } from '../dtos/ApplicationDetailResponse';
import { AddressResponse } from '../dtos/AddressResonse';

@Injectable({
  providedIn: 'root',
})
export class ApiConnectorService {
  private baseUrl: string = '';
  private addressEndpoint: string = environment.addressEndpoint;

  constructor(private http: HttpClient) {
    if (environment.name === 'local') {
      this.baseUrl = '/cc-onboarding/v1/b2b';
    } else {
      this.baseUrl = environment.apiUrl;
    }
  }

  initApplication(): Observable<ApplicationResponse> {
    let path: string = this.baseUrl + '/applications';

    return this.http.post<ApplicationResponse>(path, null);
  }

  patchApplication(
    patchApplicationRequest: PatchApplicationRequest,
    applicationId: string
  ): Observable<any> {
    let path: string = this.baseUrl + '/applications/' + applicationId;

    return this.http.patch<any>(path, patchApplicationRequest);
  }

  getActiveProducts(
    birthday: string,
    occupation: string,
    income: number,
    industry: string,
    workingIncome: boolean
  ): Observable<ProductResponse[]> {
    let path: string =
      this.baseUrl +
      '/products?income=' +
      income +
      '&birthday=' +
      birthday +
      '&occupation=' +
      occupation +
      '&workingIncome=' +
      workingIncome;

    if (industry != null) {
      path = path + '&industry=' + industry;
    }

    return this.http.get<ProductResponse[]>(path);
  }

  uploadFile(
    applicationId: string,
    file: File,
    documentType: string
  ): Observable<UploadFileResponse> {
    let formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('documentType', documentType);

    let path: string =
      this.baseUrl + '/applications/' + applicationId + '/documents';

    return this.http.post<UploadFileResponse>(path, formData);
  }

  confirmApplication(applicationId: string): Observable<ApplicationResponse> {
    let path: string =
      this.baseUrl + '/applications/' + applicationId + '/confirm';
    return this.http.post<ApplicationResponse>(path, null);
  }

  getContractPreview(applicationId: string): Observable<Blob> {
    let path: string =
      this.baseUrl + '/applications/' + applicationId + '/contract-preview';
    return this.http.get(path, { responseType: 'blob' });
  }

  signApplication(
    applicationId: string,
    signApplicationRequest: SignApplicationRequest
  ): Observable<ApplicationResponse> {
    let path: string =
      this.baseUrl + '/applications/' + applicationId + '/sign';
    return this.http.post<ApplicationResponse>(path, signApplicationRequest);
  }

  getApplication(applicationId: string): Observable<ApplicationDetailResponse> {
    let path: string = this.baseUrl + '/applications/' + applicationId;
    return this.http.get<ApplicationDetailResponse>(path);
  }

  searchAddress(street: string): Observable<AddressResponse[]> {
    let path: string = this.addressEndpoint + '?street=' + street;
    return this.http.get<AddressResponse[]>(path);
  }

  uploadGuarantee(
    applicationId: string,
    file: File
  ): Observable<ApplicationResponse> {
    let formData: FormData = new FormData();
    formData.append('file', file);

    let path: string =
      this.baseUrl + '/applications/' + applicationId + '/afterward-guarantee';

    return this.http.post<ApplicationResponse>(path, formData);
  }
}
