export enum Industry {
  BANKING = 'BANKING',
  BUILDING = 'BUILDING',
  MINING = 'MINING',
  CHEMISTRY = 'CHEMISTRY',
  SERVICES = 'SERVICES',
  ENERGY = 'ENERGY',
  HEALTH = 'HEALTH',
  GAMBLING = 'GAMBLING',
  CARRIAGE = 'CARRIAGE',
  TRADING = 'TRADING',
  GASTRONOMY = 'GASTRONOMY',
  FARMING = 'FARMING',
  PUBLIC_SERVICES = 'PUBLIC_SERVICES',
  PASSENGER_TRANSPORT = 'PASSENGER_TRANSPORT',
  PRODUCTION = 'PRODUCTION',
  CLEANING = 'CLEANING',
  FOUNDATION = 'FOUNDATION',
  COMMUNICATION = 'COMMUNICATION',
  INSURANCE = 'INSURANCE',
  WEAPONS = 'WEAPONS',
}

export const Industries = [
  { value: 'BANKING', label: 'Banken' },
  { value: 'BUILDING', label: 'Baugewerbe' },
  { value: 'MINING', label: 'Bergbau' },
  { value: 'CHEMISTRY', label: 'Chemie' },
  { value: 'SERVICES', label: 'Dienstleistung' },
  { value: 'ENERGY', label: 'Engergieversorgung' },
  { value: 'HEALTH', label: 'Gesundheitswesen' },
  { value: 'GAMBLING', label: 'Glücksspiel' },
  { value: 'CARRIAGE', label: 'Gütertransport' },
  { value: 'TRADING', label: 'Handel' },
  { value: 'GASTRONOMY', label: 'Hotel/Gastronomie' },
  { value: 'FARMING', label: 'Landwirtschaft' },
  { value: 'PUBLIC_SERVICES', label: 'Öffentlicher Dienst' },
  {
    value: 'PASSENGER_TRANSPORT',
    label: 'Personentransport/Postdienstleistung',
  },
  { value: 'PRODUCTION', label: 'Produktion, Industrie' },
  { value: 'CLEANING', label: 'Reinigung' },
  { value: 'FOUNDATION', label: 'Stiftung' },
  { value: 'COMMUNICATION', label: 'Verlagswesen, Kommunikation' },
  { value: 'INSURANCE', label: 'Versicherungen' },
  { value: 'WEAPONS', label: 'Waffen' },
];
