import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiConnectorService } from 'src/app/services/api-connector.service';
import * as countryDataFromJson from '../../../assets/data/country_iso_code.json';
import * as pensionerCountryFromJson from '../../../assets/data/pensioner_country_iso_code.json';
import * as zipFromJson from '../../../assets/data/zip.json';
import { PatchApplicationRequest } from 'src/app/dtos/PatchApplicationRequest';
import { AtAddress } from 'src/app/dtos/AtAddress';
import { ForeignAddress } from 'src/app/dtos/ForeignAddress';
import { Occupation, Occupations } from 'src/app/dtos/Occupation';
import { Industries, Industry } from 'src/app/dtos/Industry';
import { FinancialInfoRequest } from 'src/app/dtos/FinancialInfoRequest';
import {
  atLeastOneIncomeType,
  atLeastOneIncomeTypeForStudent,
  isAfter,
  isBefore,
} from 'src/app/validators/CustomValidator';
import { FormUtils } from 'src/app/utils/FormUtil';
import { Emitter, Emittable } from '@ngxs-labs/emitter';
import { FinancialInfoState } from 'src/app/states/FinancialInfoState';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ApplicationResponse } from 'src/app/dtos/ApplicationResponse';
import { CurrentApplicationState } from 'src/app/states/CurrentApplicationState';
import { AppState, AppStateModel, Screen } from 'src/app/states/AppState';

@Component({
  selector: 'app-financial-info',
  templateUrl: './financial-info.component.html',
  styleUrls: ['./financial-info.component.scss'],
})
export class FinancialInfoComponent implements OnInit {
  constructor(
    private api: ApiConnectorService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  showIndustryInfo: boolean = false;
  showEmployerInfo: boolean = false;
  showIncomeInfo: boolean = false;
  showAtLineInfo: boolean = false;
  showForeignLineInfo: boolean = false;

  @Select(CurrentApplicationState.getCurrentApplication)
  getCurrentApplication$!: Observable<ApplicationResponse>;
  currentApplication: ApplicationResponse = {};
  @Select(FinancialInfoState.getFinancialInfo)
  getFinancialInfo$!: Observable<FinancialInfoRequest>;
  @Emitter(FinancialInfoState.updateFinancialInfo)
  updateFinancialInfo$!: Emittable<FinancialInfoRequest>;
  @Select(AppState.getAppState)
  getAppState$!: Observable<AppStateModel>;
  appState: AppStateModel = {};
  @Emitter(AppState.updateAppState)
  updateAppState$!: Emittable<AppStateModel>;
  submitText: string = 'Weiter';
  earliestDate: Date = new Date('1900-01-01');

  backendError: boolean = false;
  financialInfoForm: FormGroup = {} as FormGroup;
  countries: any = (countryDataFromJson as any).default;
  pensionerCountries: any = (pensionerCountryFromJson as any).default;
  zips: any = (zipFromJson as any).default;
  patchApplicationRequest: PatchApplicationRequest = {};
  financialInfoRequest: FinancialInfoRequest = {};
  atAddress: AtAddress = {};
  foreignAddress: ForeignAddress = {};
  occupations = Occupations;
  industries = Industries;
  showOtherIncome: boolean = false;
  showAtAddress: boolean = false;
  showForeignAddress: boolean = false;
  showIndustry: boolean = false;
  showEmployerCountry: boolean = false;
  showPensionerCountry: boolean = false;
  showWorkingStudent: boolean = false;
  showEmployer: boolean = false;
  showEmployedSince: boolean = false;
  workingIncomeBorder: boolean = false;
  selfEmployedBorder: boolean = false;
  stateIncomeBorder: boolean = false;
  apprenticeIncomeBorder: boolean = false;
  today: Date = new Date();
  employerLabel: string = 'Arbeitgeber: *';
  employerError: string = 'Bitte einen korrekten Arbeitgeber eingeben';
  employerPlaceholder: string = 'Bitte den Arbeitgeber eingeben';
  employedSinceLable: string = 'Beschäftigt seit: *';
  incomeLablel: string = 'Netto Einkommen monatlich: *';
  incomePlaceholder: string = 'Bitte das monatliche Netto Einkommen eingeben';
  incomeError: string = 'Bitte ein korrektes Einkommen eingeben';
  //latestPossibleEmployedSince: Date = new Date();
  showWorkingIncomeAmount: boolean = false;
  shwoSelfEmployedIncomeAmount: boolean = false;
  showStateIncomeAmount: boolean = false;
  showOngoingIncomeAmount: boolean = false;
  showApprenticeIncomeAmount: boolean = false;
  showInheritIncomeAmount: boolean = false;
  showSaleIncomeAmount: boolean = false;
  showInsuranceIncomeAmount: boolean = false;
  showSpendingIncomeAmount: boolean = false;
  showIncomeInfoBox: boolean = false;

  ngOnInit(): void {
    this.getAppState$.subscribe((value) => (this.appState = value));
    this.appState.currentScreen = Screen.FINANCIAL_INFO;
    this.updateAppState$.emit(this.appState);
    if (this.appState.summaryReached) {
      this.submitText = 'Zurück zur Kontrolle';
    }
    // this.latestPossibleEmployedSince.setMonth(
    //   this.latestPossibleEmployedSince.getMonth() - 3
    // );

    // this.latestPossibleEmployedSince.setDate(
    //   this.latestPossibleEmployedSince.getDate() - 1
    // );

    this.getCurrentApplication$.subscribe(
      (value) => (this.currentApplication = value)
    );
    //setup form
    this.financialInfoForm = this.formBuilder.group(
      {
        occupation: [null, [Validators.required]],
        industry: [null, []],
        employer: [null, []],
        employedSince: [null, []],
        employerCountry: [null, []],
        pensionerCountry: [null, []],
        atLine1: [null, []],
        atLine2: [null, []],
        atZip: [null, []],
        atPlace: [null, []],
        forLine1: [null, []],
        forLine2: [null, []],
        forLine3: [null, []],
        workingIncomeAmount: [
          0,
          [Validators.required, Validators.min(0), Validators.max(2147483647)],
        ],
        selfEmployedIncomeAmount: [
          0,
          [Validators.required, Validators.min(0), Validators.max(2147483647)],
        ],
        stateIncomeAmount: [
          0,
          [Validators.required, Validators.min(0), Validators.max(2147483647)],
        ],
        ongoingIncomeAmount: [
          0,
          [Validators.required, Validators.min(0), Validators.max(2147483647)],
        ],
        apprenticeIncomeAmount: [
          0,
          [Validators.required, Validators.min(0), Validators.max(2147483647)],
        ],
        inheritIncomeAmount: [
          0,
          [Validators.required, Validators.min(0), Validators.max(2147483647)],
        ],
        saleIncomeAmount: [
          0,
          [Validators.required, Validators.min(0), Validators.max(2147483647)],
        ],
        otherIncomeAmount: [
          0,
          [Validators.required, Validators.min(0), Validators.max(2147483647)],
        ],
        insuranceIncomeAmount: [
          0,
          [Validators.required, Validators.min(0), Validators.max(2147483647)],
        ],
        spendingIncomeAmount: [
          0,
          [Validators.required, Validators.min(0), Validators.max(2147483647)],
        ],
        workingIncome: [false, []],
        selfEmployedIncome: [false, []],
        stateIncome: [false, []],
        ongoingIncome: [false, []],
        apprenticeIncome: [false, []],
        inheritIncome: [false, []],
        saleIncome: [false, []],
        otherIncome: [false, []],
        insuranceIncome: [false, []],
        spendingIncome: [false, []],
        otherIncomeText: [null, []],
        workingStudent: [null, []],
        stateIncomeText: [null, []],
      },
      {
        validators: [atLeastOneIncomeType()],
      }
    );

    //conditional other occupation field
    this.financialInfoForm.controls['occupation'].valueChanges.subscribe(
      (value) => {
        this.renderIndustry(false);
        this.renderEmployerCountry(false);
        this.renderPensionerCountry(false);
        this.renderWorkingStudent(false);
        this.renderEmployer(false);
        this.renderEmployedSince(false);
        this.renderWorkingIncome(false);
        this.renderSelfEmployedIncome(false);
        this.renderStateIncome(false);

        this.employerLabel = 'Arbeitgeber: *';
        this.employerError = 'Bitte einen korrekten Arbeitgeber eingeben';
        this.employerPlaceholder = 'Bitte den Arbeitgeber eingeben';
        this.employedSinceLable = 'Beschäftigt seit: *';
        this.incomeLablel = 'Netto Einkommen monatlich: *';
        this.incomePlaceholder =
          'Bitte das monatliche Netto Einkommen eingeben';
        this.incomeError = 'Bitte ein korrektes Einkommen eingeben';

        if (value === Occupation.PENSIONER) {
          this.employerLabel = 'Pensionsauszahlende Stelle: *';
          this.employerError =
            'Bitte eine korrekten pensionsauszahlende Stelle eingeben';
          this.employerPlaceholder =
            'Bitte die pensionsauszahlende Stelle eingeben';
          this.employedSinceLable = 'In Pension seit: *';
          this.incomeLablel = 'Netto Pension monatlich: *';
          this.incomePlaceholder =
            'Bitte die monatliche Netto Pension eingeben';
          this.incomeError = 'Bitte eine korrekte Netto Pension eingeben';
          this.renderWorkingIncome(true);
          this.renderPensionerCountry(true);
          this.renderEmployer(true);
          this.renderEmployedSince(true);
        } else if (value == Occupation.STUDENT) {
          this.renderWorkingStudent(true);
        } else if (value == null) {
          //do nothing
        } else if (value == Occupation.SELF_EMPLOYED) {
          this.selfEmployedBorder = true;
          this.renderSelfEmployedIncome(true);
          this.renderIndustry(true);
          this.renderEmployerCountry(true);
          this.renderEmployer(true);
          this.renderEmployedSince(true);
        } else if (
          value == Occupation.EMPLOYEE ||
          value == Occupation.WORKER ||
          value == Occupation.OFFICIAL_SERVICE
        ) {
          this.renderWorkingIncome(true);
          this.renderIndustry(true);
          this.renderEmployerCountry(true);
          this.renderEmployer(true);
          this.renderEmployedSince(true);
        } else {
          //ON_LEAVE or JOBLESS
          this.renderIndustry(true);
          this.renderEmployerCountry(true);
          this.renderEmployer(true);
          this.renderEmployedSince(true);
        }
      }
    );

    this.financialInfoForm.controls['workingStudent'].valueChanges.subscribe(
      (value) => {
        this.renderIndustry(false);
        this.renderEmployer(false);
        this.renderEmployedSince(false);
        this.renderEmployerCountry(false);
        this.renderFormForWorkingStudent(false);
        this.renderFormForNonWorkingStudent(false);

        if (value == true) {
          this.renderIndustry(true);
          this.renderEmployer(true);
          this.renderEmployedSince(true);
          this.renderEmployerCountry(true);
          this.renderFormForWorkingStudent(true);
        }

        if (value == false) {
          this.renderFormForNonWorkingStudent(true);
        }
      }
    );

    //conditional other income field
    this.financialInfoForm.controls['otherIncome'].valueChanges.subscribe(
      (value) => {
        const otherIncomeText =
          this.financialInfoForm.controls['otherIncomeText'];

        if (value) {
          this.showOtherIncome = true;
          otherIncomeText.setValidators([
            Validators.required,
            Validators.maxLength(100),
            Validators.pattern(`^[0-9A-Za-zÀ-ž∂\u0370-\u03FF /,.'-]+$`),
          ]);
        } else {
          this.showOtherIncome = false;
          this.financialInfoForm.controls['otherIncomeAmount'].patchValue(0);
          otherIncomeText.clearValidators();
          otherIncomeText.setValue(null);
        }

        otherIncomeText.updateValueAndValidity();
      }
    );

    //conditional address block
    this.financialInfoForm.controls['employerCountry'].valueChanges.subscribe(
      (value) => {
        const atLine1 = this.financialInfoForm.controls['atLine1'];
        const atLine2 = this.financialInfoForm.controls['atLine2'];
        const atZip = this.financialInfoForm.controls['atZip'];
        const atPlace = this.financialInfoForm.controls['atPlace'];
        const forLine1 = this.financialInfoForm.controls['forLine1'];
        const forLine2 = this.financialInfoForm.controls['forLine2'];
        const forLine3 = this.financialInfoForm.controls['forLine3'];

        forLine1.clearValidators();
        forLine1.setValue(null);
        forLine2.clearValidators();
        forLine2.setValue(null);
        forLine3.clearValidators();
        forLine3.setValue(null);
        atLine1.clearValidators();
        atLine1.setValue(null);
        atLine2.clearValidators();
        atLine2.setValue(null);
        atZip.clearValidators();
        atZip.setValue(null);
        atPlace.clearValidators();
        atPlace.setValue(null);

        if (value === 'AT') {
          this.showAtAddress = true;
          this.showForeignAddress = false;

          atLine1.setValidators([
            Validators.required,
            Validators.pattern(`^[0-9A-Za-zÀ-ž∂\u0370-\u03FF /,.'-]+$`),
            Validators.maxLength(40),
          ]);
          atLine2.setValidators([
            Validators.pattern(`^[0-9A-Za-zÀ-ž∂\u0370-\u03FF /,.'-]+$`),
            Validators.maxLength(40),
          ]);
          atZip.setValidators([Validators.required]);
          atPlace.setValidators([
            Validators.required,
            Validators.maxLength(25),
            Validators.pattern(`^[A-Za-zÀ-ž∂\u0370-\u03FF ,.'-]+$`),
          ]);
        } else if (value == null) {
          this.showAtAddress = false;
          this.showForeignAddress = false;
        } else {
          this.showForeignAddress = true;
          this.showAtAddress = false;

          forLine1.setValidators([
            Validators.required,
            Validators.pattern(`^[0-9A-Za-zÀ-ž∂\u0370-\u03FF /,.'-]+$`),
            Validators.maxLength(40),
          ]);
          forLine2.setValidators([
            Validators.required,
            Validators.pattern(`^[0-9A-Za-zÀ-ž∂\u0370-\u03FF /,.'-]+$`),
            Validators.maxLength(40),
          ]);
          forLine3.setValidators([
            Validators.pattern(`^[0-9A-Za-zÀ-ž∂\u0370-\u03FF /,.'-]+$`),
            Validators.maxLength(40),
          ]);
        }

        atLine1.updateValueAndValidity();
        atLine2.updateValueAndValidity();
        atZip.updateValueAndValidity();
        atPlace.updateValueAndValidity();
        forLine1.updateValueAndValidity();
        forLine2.updateValueAndValidity();
        forLine3.updateValueAndValidity();
      }
    );

    //autofill place
    this.financialInfoForm.controls['atZip'].valueChanges.subscribe((value) => {
      const atPlace = this.financialInfoForm.controls['atPlace'];

      if (value === null) {
        atPlace.setValue(null);
      } else {
        let placeFromObject: string = this.getPlaceFromZip(value);

        if (placeFromObject.length > 25) {
          placeFromObject = placeFromObject.substring(0, 25);
        }
        atPlace.setValue(placeFromObject);
      }

      atPlace.updateValueAndValidity();
    });

    this.financialInfoForm.controls['workingIncome'].valueChanges.subscribe(
      (value) => {
        if (value) {
          this.showWorkingIncomeAmount = true;
        } else {
          this.showWorkingIncomeAmount = false;
          this.financialInfoForm.controls['workingIncomeAmount'].patchValue(0);
        }
      }
    );

    this.financialInfoForm.controls[
      'selfEmployedIncome'
    ].valueChanges.subscribe((value) => {
      if (value) {
        this.shwoSelfEmployedIncomeAmount = true;
      } else {
        this.shwoSelfEmployedIncomeAmount = false;
        this.financialInfoForm.controls['selfEmployedIncomeAmount'].patchValue(
          0
        );
      }
    });

    this.financialInfoForm.controls['stateIncome'].valueChanges.subscribe(
      (value) => {
        const stateIncomeText =
          this.financialInfoForm.controls['stateIncomeText'];
        if (value) {
          this.showStateIncomeAmount = true;
          stateIncomeText.setValidators([
            Validators.required,
            Validators.maxLength(100),
            Validators.pattern(`^[0-9A-Za-zÀ-ž∂\u0370-\u03FF /,.'-]+$`),
          ]);
        } else {
          this.showStateIncomeAmount = false;
          this.financialInfoForm.controls['stateIncomeAmount'].patchValue(0);
          stateIncomeText.clearValidators();
          stateIncomeText.setValue(null);
        }
        stateIncomeText.updateValueAndValidity();
      }
    );

    this.financialInfoForm.controls['ongoingIncome'].valueChanges.subscribe(
      (value) => {
        if (value) {
          this.showOngoingIncomeAmount = true;
        } else {
          this.showOngoingIncomeAmount = false;
          this.financialInfoForm.controls['ongoingIncomeAmount'].patchValue(0);
        }
      }
    );

    this.financialInfoForm.controls['apprenticeIncome'].valueChanges.subscribe(
      (value) => {
        if (value) {
          this.showApprenticeIncomeAmount = true;
        } else {
          this.showApprenticeIncomeAmount = false;
          this.financialInfoForm.controls['apprenticeIncomeAmount'].patchValue(
            0
          );
        }
      }
    );

    this.financialInfoForm.controls['spendingIncome'].valueChanges.subscribe(
      (value) => {
        if (value) {
          this.showSpendingIncomeAmount = true;
        } else {
          this.showSpendingIncomeAmount = false;
          this.financialInfoForm.controls['spendingIncomeAmount'].patchValue(0);
        }
      }
    );

    this.financialInfoForm.controls['inheritIncome'].valueChanges.subscribe(
      (value) => {
        if (value) {
          this.showInheritIncomeAmount = true;
        } else {
          this.showInheritIncomeAmount = false;
          this.financialInfoForm.controls['inheritIncomeAmount'].patchValue(0);
        }
      }
    );

    this.financialInfoForm.controls['saleIncome'].valueChanges.subscribe(
      (value) => {
        if (value) {
          this.showSaleIncomeAmount = true;
        } else {
          this.showSaleIncomeAmount = false;
          this.financialInfoForm.controls['saleIncomeAmount'].patchValue(0);
        }
      }
    );

    this.financialInfoForm.controls['insuranceIncome'].valueChanges.subscribe(
      (value) => {
        if (value) {
          this.showInsuranceIncomeAmount = true;
        } else {
          this.showInsuranceIncomeAmount = false;
          this.financialInfoForm.controls['insuranceIncomeAmount'].patchValue(
            0
          );
        }
      }
    );

    this.getFinancialInfo$.subscribe((value) => {
      if (value.occupation != undefined) {
        this.financialInfoForm.controls['occupation'].patchValue(
          value.occupation
        );
        this.financialInfoForm.controls['occupation'].disable();
      }

      if (value.workingStudent != undefined) {
        this.financialInfoForm.controls['workingStudent'].patchValue(
          value.workingStudent
        );
        this.financialInfoForm.controls['workingStudent'].disable();
      }

      if (value.industry != undefined) {
        this.financialInfoForm.controls['industry'].patchValue(value.industry);
        this.financialInfoForm.controls['industry'].disable();
      }
      if (value.employer != undefined) {
        this.financialInfoForm.controls['employer'].patchValue(value.employer);
      }
      if (value.employedSince != undefined) {
        this.financialInfoForm.controls['employedSince'].patchValue(
          value.employedSince
        );
      }

      if (value.employerCountry != undefined) {
        if (value.occupation == Occupation.PENSIONER) {
          this.financialInfoForm.controls['pensionerCountry'].patchValue(
            value.employerCountry
          );
        } else {
          this.financialInfoForm.controls['employerCountry'].patchValue(
            value.employerCountry
          );
        }
      }
      /*       if (value.income != undefined) {
        this.financialInfoForm.controls['income'].patchValue(value.income);
        this.financialInfoForm.controls['income'].disable();
      } */
      if (value.workingIncome != undefined) {
        this.financialInfoForm.controls['workingIncome'].patchValue(
          value.workingIncome
        );
        this.financialInfoForm.controls['workingIncome'].disable();
      }
      if (value.selfEmployedIncome != undefined) {
        this.financialInfoForm.controls['selfEmployedIncome'].patchValue(
          value.selfEmployedIncome
        );
        this.financialInfoForm.controls['selfEmployedIncome'].disable();
      }
      if (value.stateIncome != undefined) {
        this.financialInfoForm.controls['stateIncome'].patchValue(
          value.stateIncome
        );
        this.financialInfoForm.controls['stateIncome'].disable();
      }
      if (value.ongoingIncome != undefined) {
        this.financialInfoForm.controls['ongoingIncome'].patchValue(
          value.ongoingIncome
        );
        this.financialInfoForm.controls['ongoingIncome'].disable();
      }
      if (value.apprenticeIncome != undefined) {
        this.financialInfoForm.controls['apprenticeIncome'].patchValue(
          value.apprenticeIncome
        );
        this.financialInfoForm.controls['apprenticeIncome'].disable();
      }
      if (value.inheritIncome != undefined) {
        this.financialInfoForm.controls['inheritIncome'].patchValue(
          value.inheritIncome
        );
        this.financialInfoForm.controls['inheritIncome'].disable();
      }
      if (value.saleIncome != undefined) {
        this.financialInfoForm.controls['saleIncome'].patchValue(
          value.saleIncome
        );
        this.financialInfoForm.controls['saleIncome'].disable();
      }
      if (value.otherIncome != undefined) {
        this.financialInfoForm.controls['otherIncome'].patchValue(
          value.otherIncome
        );
        this.financialInfoForm.controls['otherIncome'].disable();
      }
      if (value.insuranceIncome != undefined) {
        this.financialInfoForm.controls['insuranceIncome'].patchValue(
          value.insuranceIncome
        );
        this.financialInfoForm.controls['insuranceIncome'].disable();
      }
      if (value.spendingIncome != undefined) {
        this.financialInfoForm.controls['spendingIncome'].patchValue(
          value.spendingIncome
        );
        this.financialInfoForm.controls['spendingIncome'].disable();
      }
      if (value.workingIncomeAmount != undefined) {
        this.financialInfoForm.controls['workingIncomeAmount'].patchValue(
          value.workingIncomeAmount
        );
        this.financialInfoForm.controls['workingIncomeAmount'].disable();
      }
      if (value.selfEmployedIncomeAmount != undefined) {
        this.financialInfoForm.controls['selfEmployedIncomeAmount'].patchValue(
          value.selfEmployedIncomeAmount
        );
        this.financialInfoForm.controls['selfEmployedIncomeAmount'].disable();
      }
      if (value.stateIncomeAmount != undefined) {
        this.financialInfoForm.controls['stateIncomeAmount'].patchValue(
          value.stateIncomeAmount
        );
        this.financialInfoForm.controls['stateIncomeAmount'].disable();
      }
      if (value.ongoingIncomeAmount != undefined) {
        this.financialInfoForm.controls['ongoingIncomeAmount'].patchValue(
          value.ongoingIncomeAmount
        );
        this.financialInfoForm.controls['ongoingIncomeAmount'].disable();
      }
      if (value.apprenticeIncomeAmount != undefined) {
        this.financialInfoForm.controls['apprenticeIncomeAmount'].patchValue(
          value.apprenticeIncomeAmount
        );
        this.financialInfoForm.controls['apprenticeIncomeAmount'].disable();
      }
      if (value.inheritIncomeAmount != undefined) {
        this.financialInfoForm.controls['inheritIncomeAmount'].patchValue(
          value.inheritIncomeAmount
        );
        this.financialInfoForm.controls['inheritIncomeAmount'].disable();
      }
      if (value.saleIncomeAmount != undefined) {
        this.financialInfoForm.controls['saleIncomeAmount'].patchValue(
          value.saleIncomeAmount
        );
        this.financialInfoForm.controls['saleIncomeAmount'].disable();
      }
      if (value.otherIncomeAmount != undefined) {
        this.financialInfoForm.controls['otherIncomeAmount'].patchValue(
          value.otherIncomeAmount
        );
        this.financialInfoForm.controls['otherIncomeAmount'].disable();
      }
      if (value.insuranceIncomeAmount != undefined) {
        this.financialInfoForm.controls['insuranceIncomeAmount'].patchValue(
          value.insuranceIncomeAmount
        );
        this.financialInfoForm.controls['insuranceIncomeAmount'].disable();
      }
      if (value.spendingIncomeAmount != undefined) {
        this.financialInfoForm.controls['spendingIncomeAmount'].patchValue(
          value.spendingIncomeAmount
        );
        this.financialInfoForm.controls['spendingIncomeAmount'].disable();
      }
      if (value.otherIncomeText != undefined) {
        this.financialInfoForm.controls['otherIncomeText'].patchValue(
          value.otherIncomeText
        );
      }
      if (value.stateIncomeText !== undefined) {
        this.financialInfoForm.controls['stateIncomeText'].patchValue(
          value.stateIncomeText
        );
      }
      if (value.atAddress?.line1 != undefined) {
        this.financialInfoForm.controls['atLine1'].patchValue(
          value.atAddress.line1
        );
      }
      if (value.atAddress?.line2 != undefined) {
        this.financialInfoForm.controls['atLine2'].patchValue(
          value.atAddress.line2
        );
      }
      if (value.atAddress?.zip != undefined) {
        this.financialInfoForm.controls['atZip'].patchValue(
          value.atAddress.zip
        );
      }
      if (value.atAddress?.place != undefined) {
        this.financialInfoForm.controls['atPlace'].patchValue(
          value.atAddress.place
        );
      }
      if (value.foreignAddress?.line1 != undefined) {
        this.financialInfoForm.controls['forLine1'].patchValue(
          value.foreignAddress.line1
        );
      }
      if (value.foreignAddress?.line2 != undefined) {
        this.financialInfoForm.controls['forLine2'].patchValue(
          value.foreignAddress.line2
        );
      }
      if (value.foreignAddress?.line3 != undefined) {
        this.financialInfoForm.controls['forLine3'].patchValue(
          value.foreignAddress.line3
        );
      }
    });
  }

  onSubmit(): void {
    let countryForBackend =
      this.financialInfoForm.controls['employerCountry'].value;

    if (this.showPensionerCountry) {
      countryForBackend =
        this.financialInfoForm.controls['pensionerCountry'].value;
    }

    this.atAddress = {
      line1: this.financialInfoForm.controls['atLine1'].value,
      line2: FormUtils.handleClearInput(
        this.financialInfoForm.controls['atLine2'].value
      )!,
      zip: this.financialInfoForm.controls['atZip'].value,
      place: this.financialInfoForm.controls['atPlace'].value,
    };

    this.foreignAddress = {
      line1: this.financialInfoForm.controls['forLine1'].value,
      line2: this.financialInfoForm.controls['forLine2'].value,
      line3: FormUtils.handleClearInput(
        this.financialInfoForm.controls['forLine3'].value
      )!,
    };

    this.financialInfoRequest = {
      workingStudent: this.financialInfoForm.controls['workingStudent'].value,
      occupation: this.financialInfoForm.controls['occupation'].value,
      industry: this.financialInfoForm.controls['industry'].value,
      employer: this.financialInfoForm.controls['employer'].value,
      employedSince: this.financialInfoForm.controls['employedSince'].value,
      employerCountry: countryForBackend,
      income: this.calculateIncome(),
      workingIncome: this.financialInfoForm.controls['workingIncome'].value,
      selfEmployedIncome:
        this.financialInfoForm.controls['selfEmployedIncome'].value,
      stateIncome: this.financialInfoForm.controls['stateIncome'].value,
      ongoingIncome: this.financialInfoForm.controls['ongoingIncome'].value,
      apprenticeIncome:
        this.financialInfoForm.controls['apprenticeIncome'].value,
      inheritIncome: this.financialInfoForm.controls['inheritIncome'].value,
      saleIncome: this.financialInfoForm.controls['saleIncome'].value,
      spendingIncome: this.financialInfoForm.controls['spendingIncome'].value,
      otherIncome: this.financialInfoForm.controls['otherIncome'].value,
      insuranceIncome: this.financialInfoForm.controls['insuranceIncome'].value,
      workingIncomeAmount:
        this.financialInfoForm.controls['workingIncomeAmount'].value,
      selfEmployedIncomeAmount:
        this.financialInfoForm.controls['selfEmployedIncomeAmount'].value,
      stateIncomeAmount:
        this.financialInfoForm.controls['stateIncomeAmount'].value,
      ongoingIncomeAmount:
        this.financialInfoForm.controls['ongoingIncomeAmount'].value,
      apprenticeIncomeAmount:
        this.financialInfoForm.controls['apprenticeIncomeAmount'].value,
      inheritIncomeAmount:
        this.financialInfoForm.controls['inheritIncomeAmount'].value,
      saleIncomeAmount:
        this.financialInfoForm.controls['saleIncomeAmount'].value,
      spendingIncomeAmount:
        this.financialInfoForm.controls['spendingIncomeAmount'].value,
      otherIncomeAmount:
        this.financialInfoForm.controls['otherIncomeAmount'].value,
      insuranceIncomeAmount:
        this.financialInfoForm.controls['insuranceIncomeAmount'].value,
      otherIncomeText: this.financialInfoForm.controls['otherIncomeText'].value,
      stateIncomeText: this.financialInfoForm.controls['stateIncomeText'].value,
      atAddress: this.atAddress,
      foreignAddress: this.foreignAddress,
    };

    this.patchApplicationRequest = {
      financialInfo: this.financialInfoRequest,
    };

    this.api
      .patchApplication(
        this.patchApplicationRequest,
        this.currentApplication.applicationId!
      )
      .subscribe({
        complete: () => {
          this.updateFinancialInfo$.emit(this.financialInfoRequest);
          if (this.appState.summaryReached) {
            this.router.navigateByUrl('summary');
          } else {
            this.router.navigateByUrl('product-selection');
          }
        },
        error: (error) => {
          this.backendError = true;
          console.log(error);
        },
      });
  }

  getPlaceFromZip(zip: string): string {
    let helper: any[] = this.zips;
    let place: string = '';
    helper.forEach((i) => {
      if (i.plz == zip) {
        place = i.ort;
        return;
      }
    });
    return place;
  }

  back(): void {
    this.router.navigateByUrl('bank-info');
  }

  renderIndustry(active: boolean): void {
    const industry = this.financialInfoForm.controls['industry'];
    this.showIndustry = active;

    if (active) {
      industry.setValidators([Validators.required]);
    } else {
      industry.setValue(null);
      industry.clearValidators();
    }
    industry.updateValueAndValidity();
  }

  renderEmployerCountry(active: boolean): void {
    const employerCountry = this.financialInfoForm.controls['employerCountry'];
    this.showEmployerCountry = active;

    if (active) {
      employerCountry.setValidators([Validators.required]);
    } else {
      employerCountry.setValue(null);
      employerCountry.clearValidators();
    }

    employerCountry.updateValueAndValidity();
  }

  renderPensionerCountry(active: boolean): void {
    const pensionerCountry =
      this.financialInfoForm.controls['pensionerCountry'];
    this.showPensionerCountry = active;

    if (active) {
      pensionerCountry.setValidators([Validators.required]);
    } else {
      pensionerCountry.setValue(null);
      pensionerCountry.clearValidators();
    }

    pensionerCountry.updateValueAndValidity();
  }

  renderWorkingStudent(active: boolean): void {
    const workingStudent = this.financialInfoForm.controls['workingStudent'];
    this.showWorkingStudent = active;

    if (active) {
      workingStudent.setValidators([Validators.required]);
    } else {
      workingStudent.clearValidators();
      workingStudent.setValue(null);
    }

    workingStudent.updateValueAndValidity();
  }

  renderEmployer(active: boolean): void {
    const employer = this.financialInfoForm.controls['employer'];
    this.showEmployer = active;

    if (active) {
      employer.setValidators([
        Validators.required,
        Validators.pattern(`^[0-9A-Za-zÀ-ž∂\u0370-\u03FF /,.'-]+$`),
        Validators.maxLength(100),
      ]);
    } else {
      employer.setValue(null);
      employer.clearValidators();
    }
    employer.updateValueAndValidity();
  }

  renderEmployedSince(active: boolean): void {
    const employedSince = this.financialInfoForm.controls['employedSince'];
    this.showEmployedSince = active;

    if (active) {
      employedSince.setValidators([
        Validators.required,
        isBefore(this.today),
        isAfter(this.earliestDate),
      ]);
    } else {
      employedSince.clearValidators();
      employedSince.setValue(null);
    }
    employedSince.updateValueAndValidity();
  }

  renderWorkingIncome(active: boolean): void {
    const workingIncome = this.financialInfoForm.controls['workingIncome'];
    this.workingIncomeBorder = active;

    if (active) {
      workingIncome.setValidators([Validators.requiredTrue]);
    } else {
      workingIncome.clearValidators();
    }
    workingIncome.updateValueAndValidity();
  }

  renderSelfEmployedIncome(active: boolean): void {
    const selfEmployedIncome =
      this.financialInfoForm.controls['selfEmployedIncome'];
    this.selfEmployedBorder = active;

    if (active) {
      selfEmployedIncome.setValidators([Validators.requiredTrue]);
    } else {
      selfEmployedIncome.clearValidators();
    }
    selfEmployedIncome.updateValueAndValidity();
  }

  renderStateIncome(active: boolean): void {
    const stateIncome = this.financialInfoForm.controls['stateIncome'];
    this.stateIncomeBorder = active;

    if (active) {
      stateIncome.setValidators([Validators.requiredTrue]);
    } else {
      stateIncome.clearValidators();
    }
    stateIncome.updateValueAndValidity();
  }

  renderFormForWorkingStudent(active: boolean): void {
    this.workingIncomeBorder = active;
    this.stateIncomeBorder = active;
    this.selfEmployedBorder = active;
    this.apprenticeIncomeBorder = active;

    if (active) {
      this.financialInfoForm.setValidators([atLeastOneIncomeTypeForStudent()]);
    } else {
      this.financialInfoForm.setValidators([atLeastOneIncomeType()]);
    }

    this.financialInfoForm.updateValueAndValidity();
  }

  renderFormForNonWorkingStudent(active: boolean): void {
    if (active) {
      this.financialInfoForm.clearValidators();
    } else {
      this.financialInfoForm.setValidators([atLeastOneIncomeType()]);
    }
    this.financialInfoForm.updateValueAndValidity();
  }

  calculateIncome(): number {
    let income: number = 0;
    const occupation = this.financialInfoForm.controls['occupation'].value;
    const workingStudent =
      this.financialInfoForm.controls['workingStudent'].value;

    if (occupation == Occupation.STUDENT) {
      if (workingStudent == true) {
        income =
          income +
          this.financialInfoForm.controls['workingIncomeAmount'].value +
          this.financialInfoForm.controls['selfEmployedIncomeAmount'].value +
          this.financialInfoForm.controls['stateIncomeAmount'].value +
          this.financialInfoForm.controls['apprenticeIncomeAmount'].value;
      } else {
        income =
          income + this.financialInfoForm.controls['stateIncomeAmount'].value;
      }
    } else {
      income =
        income +
        this.financialInfoForm.controls['workingIncomeAmount'].value +
        this.financialInfoForm.controls['selfEmployedIncomeAmount'].value;
    }

    console.log('calculated income: ' + income);

    return income;
  }
}
