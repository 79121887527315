import { Injectable } from '@angular/core';
import { Receiver, EmitterAction } from '@ngxs-labs/emitter';
import { State, Selector, StateContext } from '@ngxs/store';
import { FinancialInfoRequest } from '../dtos/FinancialInfoRequest';

export const EmptyFinancialInfoRequest = {
  occupation: undefined,
  industry: undefined,
  employer: undefined,
  employedSince: undefined,
  employerCountry: undefined,
  atAddress: undefined,
  foreignAddress: undefined,
  income: undefined,
  workingIncome: undefined,
  selfEmployedIncome: undefined,
  stateIncome: undefined,
  ongoingIncome: undefined,
  apprenticeIncome: undefined,
  inheritIncome: undefined,
  saleIncome: undefined,
  otherIncome: undefined,
  insuranceIncome: undefined,
  spendingIncome: undefined,
  otherIncomeText: undefined,
  workingStudent: undefined,
  workingIncomeAmount: undefined,
  selfEmployedIncomeAmount: undefined,
  stateIncomeAmount: undefined,
  ongoingIncomeAmount: undefined,
  apprenticeIncomeAmount: undefined,
  inheritIncomeAmount: undefined,
  saleIncomeAmount: undefined,
  otherIncomeAmount: undefined,
  insuranceIncomeAmount: undefined,
  spendingIncomeAmount: undefined,
  stateIncomeText: undefined,
};

@State<FinancialInfoRequest>({
  name: 'financialInfo',
  defaults: EmptyFinancialInfoRequest,
})
@Injectable()
export class FinancialInfoState {
  @Selector()
  public static getFinancialInfo(
    state: FinancialInfoRequest
  ): FinancialInfoRequest {
    return state;
  }
  @Receiver()
  public static updateFinancialInfo(
    { getState, patchState }: StateContext<FinancialInfoRequest>,
    { payload }: EmitterAction<FinancialInfoRequest>
  ) {
    const state = getState();
    patchState({
      ...state,
      ...payload,
    });
  }
}
