import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Emitter, Emittable } from '@ngxs-labs/emitter';
import { KeycloakService } from 'keycloak-angular';
import { ApplicationResponse } from 'src/app/dtos/ApplicationResponse';
import { ApiConnectorService } from 'src/app/services/api-connector.service';
import { StateService } from 'src/app/services/state.service';
import { CurrentApplicationState } from 'src/app/states/CurrentApplicationState';
import { environment } from 'src/environments/environment';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppState, AppStateModel, Screen } from 'src/app/states/AppState';
import {
  CurrentErrorState,
  ErrorModel,
} from 'src/app/states/CurrentErrorState';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  constructor(
    private api: ApiConnectorService,
    private router: Router,
    private keycloak: KeycloakService,
    private stateService: StateService
  ) {}

  @Emitter(CurrentApplicationState.updateCurrentApplication)
  updateCurrentApplication$!: Emittable<ApplicationResponse>;

  @Select(AppState.getAppState)
  getAppState$!: Observable<AppStateModel>;

  @Emitter(CurrentErrorState.updateCurrentError)
  updateCurrentError$!: Emittable<ErrorModel>;

  @Select(CurrentErrorState.getCurrentError)
  getCurrentError$!: Observable<ErrorModel>;
  currentError: ErrorModel = {};

  onHome: boolean = false;

  ngOnInit(): void {
    this.getCurrentError$.subscribe((value) => (this.currentError = value));
    this.getAppState$.subscribe((value) => {
      if (value.currentScreen === Screen.HOME) {
        this.onHome = true;
      } else {
        this.onHome = false;
      }
    });
  }

  onSubmit(): void {
    this.api.initApplication().subscribe({
      next: (response: ApplicationResponse) => {
        this.stateService.clearAllState();
        this.updateCurrentApplication$.emit(response);
        this.currentError.initFailed = false;
        this.updateCurrentError$.emit(this.currentError);
        this.router.navigateByUrl('personal-info');
      },
      error: (error) => {
        this.currentError.initFailed = true;
        this.updateCurrentError$.emit(this.currentError);
        console.log(error);
      },
    });
  }

  logout(): void {
    this.keycloak.logout();
  }

  manageAccount(): void {
    window.open(environment.account, '_blank');
  }

  back(): void {
    if (confirm('Möchten Sie den Antrag wirklich abbrechen?')) {
      this.stateService.clearAllState();
      this.router.navigateByUrl('');
    }
  }
}
