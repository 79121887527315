export class CountryUtils {
  static euCountries: string[] = [
    'AT',
    'BE',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SK',
    'SI',
    'ES',
    'SE',
  ];

  static isEuCountry(country: string): boolean {
    if (CountryUtils.euCountries.includes(country)) {
      return true;
    } else {
      return false;
    }
  }

  static isDach(country: string): boolean {
    if (country == 'AT' || country == 'DE' || country == 'CH') {
      return true;
    }

    return false;
  }
}
