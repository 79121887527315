import { Injectable } from '@angular/core';
import { Receiver, EmitterAction } from '@ngxs-labs/emitter';
import { State, Selector, StateContext } from '@ngxs/store';
import { ContactInfoRequest } from '../dtos/ContactInfoRequest';

export const EmptyContactInfoRequest = {
  livingCountry: undefined,
  street: undefined,
  houseNumber: undefined,
  zip: undefined,
  place: undefined,
  line1: undefined,
  line2: undefined,
  corCountry: undefined,
  corHouseNumber: undefined,
  corStreet: undefined,
  corLine1: undefined,
  corLine2: undefined,
  corPlace: undefined,
  corZip: undefined,
  sendToCorrespondence: undefined,
  email: undefined,
  phoneNumber: undefined,
};

@State<ContactInfoRequest>({
  name: 'contactInfo',
  defaults: EmptyContactInfoRequest,
})
@Injectable()
export class ContactInfoState {
  @Selector()
  public static getContactInfo(state: ContactInfoRequest): ContactInfoRequest {
    return state;
  }
  @Receiver()
  public static updateContactInfo(
    { getState, patchState }: StateContext<ContactInfoRequest>,
    { payload }: EmitterAction<ContactInfoRequest>
  ) {
    const state = getState();
    patchState({
      ...state,
      ...payload,
    });
  }
}
