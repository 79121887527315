import { Pipe, PipeTransform } from '@angular/core';
import { MaritalStates } from '../dtos/MaritalState';

@Pipe({
  name: 'maritalState'
})
export class MaritalStatePipe implements PipeTransform {

  transform(value: string | undefined): string | null {

    if (value === undefined || value === null) {
      return null;
    }

    for (let i = 0; i < MaritalStates.length; i++) {
      if (MaritalStates[i].value === value) {
        return MaritalStates[i].label;
      }
    }
    return null;
  }

}
