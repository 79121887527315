export enum MaritalState {
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
  DIVORCED = 'DIVORCED',
  WIDOWED = 'WIDOWED',
}

export const MaritalStates = [
  { value: 'SINGLE', label: 'Ledig' },
  { value: 'MARRIED', label: 'Eingetragene Partnerschaft/Verheiratet' },
  { value: 'DIVORCED', label: 'Geschieden' },
  { value: 'WIDOWED', label: 'Verwitwet' },
];
