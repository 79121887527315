import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PoliticalFunctions } from 'src/app/dtos/PoliticalFunction';
import { ApiConnectorService } from 'src/app/services/api-connector.service';
import {
  atLeasOnePurpose,
  isAfter,
  isBefore,
} from 'src/app/validators/CustomValidator';
import * as countryDataFromJson from '../../../assets/data/country_iso_code.json';
import { BasicInfoRequest } from 'src/app/dtos/BasicInfoRequest';
import { PatchApplicationRequest } from 'src/app/dtos/PatchApplicationRequest';
import { BasicInfoState } from 'src/app/states/BasicInfoState';
import { Emitter, Emittable } from '@ngxs-labs/emitter';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ApplicationResponse } from 'src/app/dtos/ApplicationResponse';
import { CurrentApplicationState } from 'src/app/states/CurrentApplicationState';
import { Pep, Peps } from 'src/app/dtos/Pep';
import { AppState, AppStateModel, Screen } from 'src/app/states/AppState';

@Component({
  selector: 'app-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.scss'],
})
export class BasicInfoComponent implements OnInit {
  constructor(
    private api: ApiConnectorService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  showPepInfo: boolean = false;

  @Select(CurrentApplicationState.getCurrentApplication)
  getCurrentApplication$!: Observable<ApplicationResponse>;
  currentApplication: ApplicationResponse = {};
  @Select(BasicInfoState.getBasicInfo)
  getBasicInfo$!: Observable<BasicInfoRequest>;
  basicInfo: BasicInfoRequest = {};
  @Emitter(BasicInfoState.updateBasicInfo)
  updateBasicInfo$!: Emittable<BasicInfoRequest>;
  @Select(AppState.getAppState)
  getAppState$!: Observable<AppStateModel>;
  appState: AppStateModel = {};
  @Emitter(AppState.updateAppState)
  updateAppState$!: Emittable<AppStateModel>;
  submitText: string = 'Weiter';
  earliestDate: Date = new Date('1900-01-01');

  basicInfoForm: FormGroup = {} as FormGroup;
  latestPossibleBirthday: Date = new Date();
  showPep: boolean = false;
  showPepRelative: boolean = false;
  backendError: boolean = false;
  politicalFunctions = PoliticalFunctions;
  peps = Peps;
  countries: any = (countryDataFromJson as any).default;
  basicInforRequest: BasicInfoRequest = {};
  patchApplicationRequest: PatchApplicationRequest = {};

  ngOnInit(): void {
    this.getAppState$.subscribe((value) => (this.appState = value));
    this.appState.currentScreen = Screen.BASIC_INFO;
    this.updateAppState$.emit(this.appState);
    if (this.appState.summaryReached) {
      this.submitText = 'Zurück zur Kontrolle';
    }
    this.getCurrentApplication$.subscribe(
      (value) => (this.currentApplication = value)
    );
    this.latestPossibleBirthday.setFullYear(
      this.latestPossibleBirthday.getFullYear() - 18
    );

    this.basicInfoForm = this.formBuilder.group(
      {
        ownDemand: [null, [Validators.requiredTrue]],
        cardPayment: [false, [Validators.required]],
        internetPayment: [false, [Validators.required]],
        drawCash: [false, [Validators.required]],
        pep: [null, [Validators.required]],
        activity: [null, []],
        politicalFunction: [null, []],
        relationship: [null, []],
        firstName: [null, []],
        lastName: [null, []],
        birthday: [null, []],
        nationality: [null, []],
      },
      {
        validators: [atLeasOnePurpose()],
      }
    );

    this.basicInfoForm.controls['pep'].valueChanges.subscribe((value) => {
      const activity = this.basicInfoForm.controls['activity'];
      const politicalFunction =
        this.basicInfoForm.controls['politicalFunction'];
      const relationship = this.basicInfoForm.controls['relationship'];
      const firstName = this.basicInfoForm.controls['firstName'];
      const lastName = this.basicInfoForm.controls['lastName'];
      const birthday = this.basicInfoForm.controls['birthday'];
      const nationality = this.basicInfoForm.controls['nationality'];

      this.showPep = false;
      this.showPepRelative = false;
      activity.clearValidators();
      activity.setValue(null);
      politicalFunction.clearValidators();
      politicalFunction.setValue(null);
      relationship.clearValidators();
      relationship.setValue(null);
      firstName.clearValidators();
      firstName.setValue(null);
      lastName.clearValidators();
      lastName.setValue(null);
      birthday.clearValidators();
      birthday.setValue(null);
      nationality.clearValidators();
      nationality.setValue(null);

      if (
        value == Pep.PEP ||
        value == Pep.PEP_PARTNER ||
        value == Pep.PEP_RELATIVE
      ) {
        activity.setValidators([
          Validators.required,
          Validators.pattern(`^[0-9A-Za-zÀ-ž∂\u0370-\u03FF /,.'-]+$`),
        ]);
        politicalFunction.setValidators([Validators.required]);

        this.showPep = true;

        if (value == Pep.PEP_PARTNER || value == Pep.PEP_RELATIVE) {
          relationship.setValidators([
            Validators.required,
            Validators.pattern(`^[0-9A-Za-zÀ-ž∂\u0370-\u03FF /,.'-]+$`),
          ]);

          firstName.setValidators([
            Validators.required,
            Validators.pattern(`^[A-Za-zÄäÖöÜüß -]+$`),
          ]);
          lastName.setValidators([
            Validators.required,
            Validators.pattern(`^[A-Za-zÄäÖöÜüß -]+$`),
          ]);
          birthday.setValidators([
            Validators.required,
            isBefore(this.latestPossibleBirthday),
            isAfter(this.earliestDate),
          ]);
          nationality.setValidators([Validators.required]);

          this.showPepRelative = true;
        }
      }

      activity.updateValueAndValidity();
      politicalFunction.updateValueAndValidity();
      relationship.updateValueAndValidity();
      firstName.updateValueAndValidity();
      lastName.updateValueAndValidity();
      birthday.updateValueAndValidity();
      nationality.updateValueAndValidity();
    });

    this.getBasicInfo$.subscribe((value) => {
      if (value.ownDemand != undefined) {
        this.basicInfoForm.controls['ownDemand'].patchValue(value.ownDemand);
      }
      if (value.cardPayment != undefined) {
        this.basicInfoForm.controls['cardPayment'].patchValue(
          value.cardPayment
        );
      }
      if (value.internetPayment != undefined) {
        this.basicInfoForm.controls['internetPayment'].patchValue(
          value.internetPayment
        );
      }
      if (value.drawCash != undefined) {
        this.basicInfoForm.controls['drawCash'].patchValue(value.drawCash);
      }
      if (value.pep != undefined) {
        this.basicInfoForm.controls['pep'].patchValue(value.pep);
      }
      if (value.activity != undefined) {
        this.basicInfoForm.controls['activity'].patchValue(value.activity);
      }
      if (value.function != undefined) {
        this.basicInfoForm.controls['politicalFunction'].patchValue(
          value.function
        );
      }
      if (value.relationship != undefined) {
        this.basicInfoForm.controls['relationship'].patchValue(
          value.relationship
        );
      }
      if (value.firstName != undefined) {
        this.basicInfoForm.controls['firstName'].patchValue(value.firstName);
      }
      if (value.lastName != undefined) {
        this.basicInfoForm.controls['lastName'].patchValue(value.lastName);
      }
      if (value.birthday != undefined) {
        this.basicInfoForm.controls['birthday'].patchValue(value.birthday);
      }
      if (value.nationality != undefined) {
        this.basicInfoForm.controls['nationality'].patchValue(
          value.nationality
        );
      }
    });
  }

  onSubmit(): void {
    this.basicInforRequest = {
      ownDemand: this.basicInfoForm.controls['ownDemand'].value,
      cardPayment: this.basicInfoForm.controls['cardPayment'].value,
      internetPayment: this.basicInfoForm.controls['internetPayment'].value,
      drawCash: this.basicInfoForm.controls['drawCash'].value,
      pep: this.basicInfoForm.controls['pep'].value,
      activity: this.basicInfoForm.controls['activity'].value,
      function: this.basicInfoForm.controls['politicalFunction'].value,
      relationship: this.basicInfoForm.controls['relationship'].value,
      firstName: this.basicInfoForm.controls['firstName'].value,
      lastName: this.basicInfoForm.controls['lastName'].value,
      birthday: this.basicInfoForm.controls['birthday'].value,
      nationality: this.basicInfoForm.controls['nationality'].value,
    };

    this.patchApplicationRequest = {
      basicInfo: this.basicInforRequest,
    };

    this.api
      .patchApplication(
        this.patchApplicationRequest,
        this.currentApplication.applicationId!
      )
      .subscribe({
        complete: () => {
          this.updateBasicInfo$.emit(this.basicInforRequest);
          this.getAppState$.subscribe((value) => (this.appState = value));
          if (this.appState.summaryReached) {
            this.router.navigateByUrl('summary');
          } else {
            this.router.navigateByUrl('service-info');
          }
        },
        error: (error) => {
          this.backendError = true;
          console.log(error);
        },
      });
  }

  back(): void {
    this.router.navigateByUrl('product-selection');
  }
}
