import { Pipe, PipeTransform } from '@angular/core';
import { ProductResponse } from '../dtos/ProductResponse';
import { ConditionPipe } from './condition.pipe';

@Pipe({
  name: 'product',
})
export class ProductPipe implements PipeTransform {
  constructor(private conditionPipe: ConditionPipe) {}

  transform(product: ProductResponse | undefined): string | null {
    if (product == undefined || product == null) {
      return null;
    }

    if (product.condition === null || product.condition === undefined) {
      return product.productName + ' ' + product.brand + ' Hauptkarte';
    } else {
      return (
        product.productName +
        ' ' +
        product.brand +
        ' Hauptkarte' +
        ' ' +
        this.conditionPipe.transform(product.condition)
      );
    }
  }
}
