import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PersonalInfoRequest } from 'src/app/dtos/PersonalInfoRequest';
import { ApiConnectorService } from 'src/app/services/api-connector.service';
import { Salutation, Salutations } from 'src/app/dtos/Salutation';
import { Observable, timeout } from 'rxjs';
import { ContactInfoRequest } from 'src/app/dtos/ContactInfoRequest';
import { LegitimationInfoRequest } from 'src/app/dtos/LegitimationInfoRequest';
import { BankInfoRequest } from 'src/app/dtos/BankInfoRequest';
import { FinancialInfoRequest } from 'src/app/dtos/FinancialInfoRequest';
import { registerLocaleData } from '@angular/common';
import locale from '@angular/common/locales/de-AT';
import { Select } from '@ngxs/store';
import { PersonalInfoState } from 'src/app/states/PersonalInfoState';
import { ContactInfoState } from 'src/app/states/ContactInfoState';
import { LegitimationInfoState } from 'src/app/states/LegitimationInfoState';
import { BankInfoState } from 'src/app/states/BankInfoState';
import { FinancialInfoState } from 'src/app/states/FinancialInfoState';
import { ProductResponse } from 'src/app/dtos/ProductResponse';
import { SelectedProductState } from 'src/app/states/SelectedProductState';
import { BasicInfoRequest } from 'src/app/dtos/BasicInfoRequest';
import { BasicInfoState } from 'src/app/states/BasicInfoState';
import { ServiceInfoRequest } from 'src/app/dtos/ServiceInfoRequest';
import { ServiceInfoState } from 'src/app/states/ServiceInfoState';
import { ApplicationResponse } from 'src/app/dtos/ApplicationResponse';
import { CurrentApplicationState } from 'src/app/states/CurrentApplicationState';
import { Emitter, Emittable } from '@ngxs-labs/emitter';
import { PaymentInfoState } from 'src/app/states/PaymentInfoState';
import { PaymentInfoRequest } from 'src/app/dtos/PaymentInfoRequest';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppState, AppStateModel, Screen } from 'src/app/states/AppState';
import { FeeCondition } from 'src/app/dtos/FeeCondition';
import { Occupation } from 'src/app/dtos/Occupation';
registerLocaleData(locale, 'de-AT');

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit {
  constructor(
    private api: ApiConnectorService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}

  summaryForm: FormGroup = {} as FormGroup;

  @Select(PersonalInfoState.getPersonalInfo)
  getPersonalInfo$!: Observable<PersonalInfoRequest>;

  @Select(ContactInfoState.getContactInfo)
  getContactInfo$!: Observable<ContactInfoRequest>;

  @Select(LegitimationInfoState.getLegitimationInfo)
  getLegitmationInfo$!: Observable<LegitimationInfoRequest>;

  @Select(BankInfoState.getBankInfo)
  getBankInfo$!: Observable<BankInfoRequest>;

  @Select(FinancialInfoState.getFinancialInfo)
  getFinancialInfo$!: Observable<FinancialInfoRequest>;

  @Select(SelectedProductState.getSelectedProduct)
  getSelectedProduct$!: Observable<ProductResponse>;

  @Select(PaymentInfoState.getPaymentInfo)
  getPaymentInfo$!: Observable<PaymentInfoRequest>;

  @Select(BasicInfoState.getBasicInfo)
  getBasicInfo$!: Observable<BasicInfoRequest>;

  @Select(ServiceInfoState.getServiceInfo)
  getServiceInfo$!: Observable<ServiceInfoRequest>;

  @Select(CurrentApplicationState.getCurrentApplication)
  getCurrentApplication$!: Observable<ApplicationResponse>;
  currentApplication: ApplicationResponse = {};

  @Emitter(CurrentApplicationState.updateCurrentApplication)
  updateCurrentApplication$!: Emittable<ApplicationResponse>;

  @Emitter(AppState.updateAppState)
  updateAppState$!: Emittable<AppStateModel>;

  @Select(AppState.getAppState)
  getAppState$!: Observable<AppStateModel>;
  appState: AppStateModel = {};

  personalInfoRequest: PersonalInfoRequest = {};
  contactInfoRequest: ContactInfoRequest = {};
  legitimationInfoRequest: LegitimationInfoRequest = {};
  bankInfoRequest: BankInfoRequest = {};
  financialInfoRequest: FinancialInfoRequest = {};
  selectedProduct: ProductResponse = {};
  paymentInfoRequest: PaymentInfoRequest = {};
  basicInfoRequest: BasicInfoRequest = {};
  serviceInfoRequest: ServiceInfoRequest = {};
  backendError: boolean = false;
  feeConditionDescription: string = '';
  Occupation = Occupation;

  ngOnInit(): void {
    this.getAppState$.subscribe((value) => (this.appState = value));
    this.appState.summaryReached = true;
    this.appState.currentScreen = Screen.SUMMARY;
    this.updateAppState$.emit(this.appState);

    this.getCurrentApplication$.subscribe(
      (value) => (this.currentApplication = value)
    );
    this.getPersonalInfo$.subscribe(
      (value) => (this.personalInfoRequest = value)
    );

    this.getContactInfo$.subscribe(
      (value) => (this.contactInfoRequest = value)
    );

    this.getLegitmationInfo$.subscribe(
      (value) => (this.legitimationInfoRequest = value)
    );

    this.getBankInfo$.subscribe((value) => (this.bankInfoRequest = value));

    this.getFinancialInfo$.subscribe(
      (value) => (this.financialInfoRequest = value)
    );

    this.getSelectedProduct$.subscribe((value) => {
      this.selectedProduct = value;

      if (this.selectedProduct.selectedFeeCondition !== undefined) {
        this.feeConditionDescription = this.getFeeConditionDescription(
          value.selectedFeeCondition!
        );
      }
    });

    this.getPaymentInfo$.subscribe(
      (value) => (this.paymentInfoRequest = value)
    );

    this.getBasicInfo$.subscribe((value) => (this.basicInfoRequest = value));

    this.getServiceInfo$.subscribe(
      (value) => (this.serviceInfoRequest = value)
    );

    this.summaryForm = this.formBuilder.group({
      confirm: [false, [Validators.requiredTrue]],
    });
  }

  onConfirm(): void {
    this.api
      .confirmApplication(this.currentApplication.applicationId!)
      .subscribe({
        next: (response: ApplicationResponse) => {
          this.updateCurrentApplication$.emit(response);
          this.router.navigateByUrl('signing');
        },
        error: (error) => {
          this.backendError = true;
          console.log(error);
        },
      });
  }

  getFeeConditionDescription(id: string): string {
    let helper: any[] = this.selectedProduct.feeConditions!;
    let description: string = '';

    helper.forEach((i) => {
      if (i.id === id) {
        description = i.description;
        return;
      }
    });

    return description;
  }
}
