import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PatchApplicationRequest } from 'src/app/dtos/PatchApplicationRequest';
import { ApiConnectorService } from 'src/app/services/api-connector.service';
import * as banksFromJson from '../../../assets/data/blz.json';
import { BankInfoRequest } from 'src/app/dtos/BankInfoRequest';
import {
  isAfter,
  isBefore,
  validIban,
} from 'src/app/validators/CustomValidator';
import { BankInfoState } from 'src/app/states/BankInfoState';
import { Emittable, Emitter } from '@ngxs-labs/emitter';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ApplicationResponse } from 'src/app/dtos/ApplicationResponse';
import { CurrentApplicationState } from 'src/app/states/CurrentApplicationState';
import { AppState, AppStateModel, Screen } from 'src/app/states/AppState';

@Component({
  selector: 'app-bank-info',
  templateUrl: './bank-info.component.html',
  styleUrls: ['./bank-info.component.scss'],
})
export class BankInfoComponent implements OnInit {
  constructor(
    private api: ApiConnectorService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  @Emitter(BankInfoState.updateBankInfo)
  updateBankInfo$!: Emittable<BankInfoRequest>;

  @Select(CurrentApplicationState.getCurrentApplication)
  getCurrentApplication$!: Observable<ApplicationResponse>;
  currentApplication: ApplicationResponse = {};

  @Select(BankInfoState.getBankInfo)
  getBankInfo$!: Observable<BankInfoRequest>;

  @Select(AppState.getAppState)
  getAppState$!: Observable<AppStateModel>;
  appState: AppStateModel = {};
  @Emitter(AppState.updateAppState)
  updateAppState$!: Emittable<AppStateModel>;

  backendError: boolean = false;
  bankInfoForm: FormGroup = {} as FormGroup;
  patchApplicationRequest: PatchApplicationRequest = {};
  bankInfoRequest: BankInfoRequest = {};
  banks: any[] = (banksFromJson as any).default;
  showBankInfo: boolean = false;
  bankName: string = '';
  today: Date = new Date();
  showOkInfo: boolean = false;
  submitText: string = 'Weiter';
  earliestDate: Date = new Date('1900-01-01');

  ngOnInit(): void {
    this.banks.sort((a, b) => a.Bankleitzahl - b.Bankleitzahl);
    this.getAppState$.subscribe((value) => (this.appState = value));
    this.appState.currentScreen = Screen.BANK_INOF;
    this.updateAppState$.emit(this.appState);
    if (this.appState.summaryReached) {
      this.submitText = 'Zurück zur Kontrolle';
    }
    this.getCurrentApplication$.subscribe(
      (value) => (this.currentApplication = value)
    );
    this.bankInfoForm = this.formBuilder.group({
      iban: [
        null,
        [Validators.required, Validators.pattern(`^AT[0-9]{18}$`), validIban()],
      ],
      blz: [null, [Validators.required]],
      accountSince: [
        null,
        [Validators.required, isBefore(this.today), isAfter(this.earliestDate)],
      ],
      accountOk: [null, [Validators.required]],
    });

    this.bankInfoForm.controls['iban'].valueChanges.subscribe((value) => {
      const blz = this.bankInfoForm.controls['blz'];
      blz.setValue(null);
      blz.updateValueAndValidity();

      if (value.length > 0) {
        let newValue = value.substring(4, 9);
        let helper: any[] = this.banks;

        helper.forEach((i) => {
          if (i.Bankleitzahl == newValue) {
            blz.setValue(i.Bankleitzahl);
            blz.updateValueAndValidity();
            return;
          }
        });
      }
    });

    this.bankInfoForm.controls['blz'].valueChanges.subscribe((value) => {
      if (value !== null) {
        this.showBankInfo = true;
        this.bankName = this.getBankNameFromBlz(value);
      } else {
        this.showBankInfo = false;
      }
    });

    this.getBankInfo$.subscribe((value) => {
      if (value.iban != undefined) {
        this.bankInfoForm.controls['iban'].patchValue(value.iban);
      }
      if (value.blz != undefined) {
        this.bankInfoForm.controls['blz'].patchValue(value.blz);
      }
      if (value.accountOk != undefined) {
        this.bankInfoForm.controls['accountOk'].patchValue(value.accountOk);
      }
      if (value.accountSince != undefined) {
        this.bankInfoForm.controls['accountSince'].patchValue(
          value.accountSince
        );
      }
    });
  }

  onSubmit(): void {
    let bankNameForRequest: string = this.bankName;
    if (this.bankName.length > 120) {
      bankNameForRequest = this.bankName.substring(0, 120);
    }

    this.bankInfoRequest = {
      iban: this.bankInfoForm.controls['iban'].value,
      bankName: bankNameForRequest,
      blz: this.bankInfoForm.controls['blz'].value,
      accountOk: this.bankInfoForm.controls['accountOk'].value,
      accountSince: this.bankInfoForm.controls['accountSince'].value,
    };

    this.patchApplicationRequest = {
      bankInfo: this.bankInfoRequest,
    };

    this.api
      .patchApplication(
        this.patchApplicationRequest,
        this.currentApplication.applicationId!
      )
      .subscribe({
        complete: () => {
          this.updateBankInfo$.emit(this.bankInfoRequest);

          if (this.appState.summaryReached) {
            this.router.navigateByUrl('summary');
          } else {
            this.router.navigateByUrl('financial-info');
          }
        },
        error: (error) => {
          this.backendError = true;
          console.log(error);
        },
      });
  }

  getBankNameFromBlz(blz: string): string {
    let helper: any[] = this.banks;
    let name: string = '';
    helper.forEach((i) => {
      if (i.Bankleitzahl == blz) {
        name = i.Bankenname;
        return;
      }
    });
    return name;
  }

  back(): void {
    this.router.navigateByUrl('legitimation-info');
  }
}
