import { Pipe, PipeTransform } from '@angular/core';
import { Occupations } from '../dtos/Occupation';

@Pipe({
  name: 'occupation'
})
export class OccupationPipe implements PipeTransform {

  transform(value: string | undefined): string | null {

    if (value === undefined || value === null) {
      return null;
    }

    for (let i = 0; i < Occupations.length; i++) {
      if (Occupations[i].value === value) {
        return Occupations[i].label;
      }
    }
    return null;
  }

}
