export const environment = {
  apiUrl: 'https://api.tst.bawagpsk.com/paylife/gw/b2b',
  name: 'tst',
  account: 'https://login.tst.bawaggroup.com/auth/realms/paylife/account/',
  addressEndpoint: 'https://api.tst.bawag.at/master-data/gw/addresses',
  keycloak: {
    url: 'https://login.tst.bawaggroup.com/auth',
    realm: 'paylife',
    clientId: 'fe-app',
  }
};
