export enum Pep {
  PEP = 'PEP',
  NO_PEP = 'NO_PEP',
  PEP_RELATIVE = 'PEP_RELATIVE',
  PEP_PARTNER = 'PEP_PARTNER',
}

export const Peps = [
  { value: 'NO_PEP', label: 'Keine politisch exponierte Person' },
  { value: 'PEP', label: 'Politisch exponierte Person' },
  { value: 'PEP_RELATIVE', label: 'Angehöriger einer politisch exponierte Person' },
  { value: 'PEP_PARTNER', label: 'In einer Geschäftsbeziehung mit einer politisch exponierten Person' },
];
