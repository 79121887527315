<div class="mt-5 mw600">
  <div *ngIf="backendError" class="alert alert-danger" role="alert">
    Es ist ein Fehler aufgetreten
  </div>

  <form [formGroup]="bankInfoForm" (ngSubmit)="onSubmit()">
    <div class="mb-3">
      <label for="ibanControl" class="form-label">IBAN: *</label>
      <input
        type="text"
        class="form-control"
        id="ibanControl"
        placeholder="Bitte den IBAN eingeben"
        formControlName="iban"
      />
      <div
        *ngIf="
          bankInfoForm.controls['iban'].errors &&
          bankInfoForm.controls['iban'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte einen korrekten IBAN eingeben
      </div>
    </div>
    <div class="mb-3">
      <label for="blzControl" class="form-label">Bankleitzahl: *</label>
      <ng-select
        id="blzControl"
        placeholder="Bitte die Bankleitzahl auswählen"
        [items]="banks"
        bindLabel="Bankleitzahl"
        bindValue="Bankleitzahl"
        formControlName="blz"
      >
      </ng-select>
      <div
        *ngIf="
          bankInfoForm.controls['blz'].errors &&
          bankInfoForm.controls['blz'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte eine Bankleitzahl auswählen
      </div>
    </div>
    <div class="mb-3" *ngIf="showBankInfo">
      {{ bankName }}
    </div>
    <div class="mb-3">
      <label for="accountSinceControl" class="form-label"
        >Girokonto seit: *</label
      >
      <input
        type="date"
        class="form-control"
        id="accountSinceControl"
        formControlName="accountSince"
        max="{{ today | date : 'yyyy-MM-dd' }}"
        min="{{ earliestDate | date : 'yyyy-MM-dd' }}"
      />
      <div
        *ngIf="
          bankInfoForm.controls['accountSince'].errors &&
          bankInfoForm.controls['accountSince'].touched
        "
        class="alert alert-danger"
        role="alert"
      >
        Bitte ein gültiges Datum eingeben
      </div>
    </div>
    <div class="mb-2">
      <div class="row">
        <div class="col">
          Die Kontoführung ist seit Kontoeröffnung bzw. seit mehr als 1 Jahr
          einwandfrei: *
        </div>
        <div class="col-auto text-end">
          <svg
            (click)="showOkInfo = !showOkInfo"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clip-path="url(#clip0_15_702)">
              <path
                d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z"
                fill="#000066"
              />
            </g>
            <defs>
              <clipPath id="clip0_15_702">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>
    <div *ngIf="showOkInfo" class="alert alert-info" role="alert">
      d.h. insbesondere keine Mahnung wegen Überziehung
    </div>
    <div>
      <div class="form-check mb-3">
        <input
          class="form-check-input"
          type="radio"
          formControlName="accountOk"
          id="accountOkControl"
          [value]="true"
        />
        <label class="form-check-label" for="accountOkControl"> Ja </label>
      </div>

      <div class="form-check mb-3">
        <input
          class="form-check-input"
          type="radio"
          formControlName="accountOk"
          id="accountNotOkControl"
          [value]="false"
        />
        <label class="form-check-label" for="accountNotOkControl"> Nein </label>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row p-0">
        <div class="col text-start p-0">
          <button type="button" (click)="back()" class="btn btn-primary">
            Zurück
          </button>
        </div>
        <div class="col text-end p-0">
          <button
            type="submit"
            [disabled]="bankInfoForm.invalid"
            class="btn btn-primary"
          >
            {{ submitText }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
