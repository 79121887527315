export enum AcademicTitle {
  DDR = 'DDR',
  DI = 'DI',
  DI_FH = 'DI_FH',
  DIPL_HTL_ING = 'DIPL_HTL_ING',
  DIPL_KFFR = 'DIPL_KFFR',
  DIPL_OEK = 'DIPL_OEK',
  DIPL_PAED = 'DIPL_PAED',
  DIPL_TA = 'DIPL_TA',
  DIPL_VW = 'DIPL_VW',
  DKFM = 'DKFM',
  DR = 'DR',
  ING = 'ING',
  MAG = 'MAG',
  MAG_FH = 'MAG_FH',
  MMAG = 'MMAG',
}

export const AcademicTitles = [
  { value: 'DDR', label: 'DDr.' },
  { value: 'DI', label: 'DI' },
  { value: 'DI_FH', label: 'DI(FH)' },
  { value: 'DIPL_HTL_ING', label: 'Dipl.HTL-Ing.' },
  { value: 'DIPL_KFFR', label: 'Dipl.Kffr.' },
  { value: 'DIPL_OEK', label: 'Dipl.Oek.' },
  { value: 'DIPL_PAED', label: 'Dipl.Päd.' },
  { value: 'DIPL_TA', label: 'Dipl.Ta.' },
  { value: 'DIPL_VW', label: 'Dipl.-Vw' },
  { value: 'DKFM', label: 'Dkfm.' },
  { value: 'DR', label: 'Dr.' },
  { value: 'ING', label: 'Ing.' },
  { value: 'MAG', label: 'Mag.' },
  { value: 'MAG_FH', label: 'Mag(FH)' },
  { value: 'MMAG', label: 'MMag.' },
];
