import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Emitter, Emittable } from '@ngxs-labs/emitter';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ApplicationResponse } from 'src/app/dtos/ApplicationResponse';
import { ApplicationState } from 'src/app/dtos/ApplicationState';
import { StateService } from 'src/app/services/state.service';
import { AppState, AppStateModel, Screen } from 'src/app/states/AppState';
import { CurrentApplicationState } from 'src/app/states/CurrentApplicationState';
import {
  CurrentErrorState,
  ErrorModel,
} from 'src/app/states/CurrentErrorState';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss'],
})
export class ResultComponent implements OnInit {
  constructor(private router: Router, private stateService: StateService) {}

  red: boolean = false;
  yellow: boolean = false;
  green: boolean = false;
  error: boolean = false;
  waiting: boolean = false;
  errorMessage: string = '';

  @Select(CurrentApplicationState.getCurrentApplication)
  getCurrentApplication$!: Observable<ApplicationResponse>;
  currentApplication: ApplicationResponse = {};

  @Select(CurrentErrorState.getCurrentError)
  getCurrentError$!: Observable<ErrorModel>;
  currentError: ErrorModel = {};

  @Emitter(AppState.updateAppState)
  updateAppState$!: Emittable<AppStateModel>;

  @Select(AppState.getAppState)
  getAppState$!: Observable<AppStateModel>;
  appState: AppStateModel = {};

  ngOnInit(): void {
    this.getAppState$.subscribe((value) => (this.appState = value));
    this.appState.currentScreen = Screen.RESULT;
    this.updateAppState$.emit(this.appState);
    this.getCurrentApplication$.subscribe(
      (value) => (this.currentApplication = value)
    );

    this.getCurrentError$.subscribe((value) => (this.currentError = value));

    if (this.currentApplication.state === ApplicationState.CREATED) {
      this.green = true;
    } else if (this.currentApplication.state === ApplicationState.REVIEW) {
      this.yellow = true;
    } else if (this.currentApplication.state === ApplicationState.REJECTED) {
      this.red = true;
    } else if (
      this.currentApplication.state ===
      ApplicationState.WAITING_FOR_BANK_GUARANTEE
    ) {
      if (this.currentError.mailServiceFailed) {
        this.error = true;
        this.errorMessage =
          'Die Backoffice Benachrichtigung ist fehlgeschlagen. Bitte senden Sie eine Email an betrieb@paylife.at mit dem Verweis auf den Antrag ' +
          this.currentApplication.applicationId;
      } else {
        this.waiting = true;
      }
    } else {
      this.error = true;

      if (this.currentError.mailServiceFailed) {
        this.errorMessage =
          'Die Backoffice Benachrichtigung ist fehlgeschlagen. Bitte senden Sie eine Email an betrieb@paylife.at mit dem Verweis auf den Antrag ' +
          this.currentApplication.applicationId;
      } else {
        this.errorMessage = 'Es ist ein Fehler aufgetrten';
      }
    }
  }

  finish(): void {
    this.stateService.clearAllState();
    this.router.navigateByUrl('');
  }
}
